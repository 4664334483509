import React, { useRef } from "react";
import { InactiveHeader } from "src/components/inactive-header";
import { DescriptionHeader } from "src/components/ui/description-header";
import { DesktopDescriptionBanner } from "src/components/ui/desktop-description-banner";
import { MobileDescriptionBanner } from "src/components/ui/mobile-description-banner";
import { OffresFooter } from "../../offres-footer";
import { CarteAvantageJeuneMainDesktop } from "./main-desktop";
import { CarteAvantageJeuneMainMobile } from "./main-mobile";
import { ChatbotSection } from "../../chatbot-section";
import { DescriptionBanner } from "src/components/responsive/description-banner";
import { PriceIds } from "src/lib/utils";
import { Helmet } from "react-helmet";
import { useInView } from "framer-motion";

export const CarteJeuneDescriptionView = () => {
  const ref = useRef<React.ElementRef<"div">>(null);

  const isInView = useInView(ref);
  return (
    <>
      <Helmet>
        <title>Carte Avantage Jeune - SNCF Connect</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionBanner
      headerTitle="Carte Avantage Jeune"
      isStickyVisible
        desktopImage="/avantage_jeune.jpg"
        mobileImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_360x214/public/media/2022-01/1440x795_jeunes2.jpg?itok=70Yn2kN_"
        cardTitle="Carte Avantage Jeune"
        cardDescription="12 - 27 ans"
        floatingDescription="(12 - 27 ans)"
        badge="Promotion"
        badgeColor="#8de8fe"
        price="2,45 €"
        buttonLabel="Commander la carte"
        href={`/app/offre/pricing/sncf?productId=${PriceIds["carte-avantage-jeune"]}`}
      />
      <CarteAvantageJeuneMainDesktop />
      <CarteAvantageJeuneMainMobile />
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
