import { z } from "zod";

// Luhn algorithm function to validate credit card numbers
const luhnCheck = (cardNumber: string): boolean => {
  let sum = 0;
  let shouldDouble = false;
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber[i], 10);
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
};

// Function to determine card type based on the card number
const getCardType = (cardNumber: string): string | null => {
  const patterns: { [key: string]: RegExp } = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    // Add more card types if needed
  };

  for (let cardType in patterns) {
    if (patterns[cardType].test(cardNumber)) {
      return cardType;
    }
  }
  return null;
};

const isExpired = (expirationDate: string): boolean => {
  const [month, year] = expirationDate.split("/").map(Number);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year

  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return true;
  }
  return false;
};

export const PaymentFormSchema = z
  .object({
    cardNumber: z
      .string({ message: "Le numéro de carte bancaire ne peut pas être vide." })
      .refine(
        (val) => {
          const cleaned = val.replace(/\D/g, ""); // Remove all non-digit characters
          return (
            cleaned.length >= 15 && cleaned.length <= 16 && luhnCheck(cleaned)
          );
        },
        {
          message: "Numéro de carte bancaire invalide.",
        }
      ),
    expirationDate: z
      .string({ message: "La date d'expiration ne peut pas être vide." })
      .min(5, { message: "La date d'expiration doit être au format MM/AA." })
      .max(5, { message: "La date d'expiration doit être au format MM/AA." })
      .regex(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, {
        message: "Format de date d'expiration invalide.",
      })
      .refine((val) => !isExpired(val), {
        message: "La carte bancaire a expiré.",
      }),
    cvv: z
      .string({ message: "Le CVV ne peut pas être vide." })
      .min(3, { message: "Le CVV doit comporter au moins 3 chiffres." })
      .max(4, { message: "Le CVV doit comporter au plus 4 chiffres." }),
  })
  .refine(
    (data) => {
      const cardType = getCardType(data.cardNumber.replace(/\D/g, ""));
      if (cardType === "amex" && data.cvv.length !== 4) {
        return false;
      }
      if (cardType !== "amex" && data.cvv.length !== 3) {
        return false;
      }
      return true;
    },
    {
      message: "Longueur du CVV invalide pour le type de carte bancaire détecté.",
      path: ["cvv"],
    }
  );
