import { InactiveMobileHeader } from "./inactive-mobile-header";
import { InactiveDesktopHeader } from "./inactive-desktop-header";

export const InactiveHeader = () => {
  return (
    <>
      <InactiveMobileHeader />
      <InactiveDesktopHeader />
    </>
  );
};
