import { z } from "zod";

const validateDateOfBirth = (dateStr: string): boolean => {
  const parts = dateStr.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-based
  const year = parseInt(parts[2], 10);
  const birthDate = new Date(year, month, day);
  const today = new Date();
  const oldestValidDate = new Date(
    today.getFullYear() - 120,
    today.getMonth(),
    today.getDate()
  );

  return birthDate <= today && birthDate >= oldestValidDate;
};

export const InformationsSchema = z.object({
  gender: z.enum(["Madame", "Monsieur"], {
    message: "Veuillez renseigner la civilité du titulaire.",
  }),
  prenom: z
    .string({ message: "Veuillez saisir votre prénom." })
    .superRefine((val, ctx) => {
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Le format du prénom est incorrect.",
        });
      }
    }),
  nom: z
    .string({ message: "Veuillez saisir votre nom." })
    .superRefine((val, ctx) => {
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Le format du nom est incorrect.",
        });
      }
    }),
  email: z
    .string({
      message: "Veuillez saisir votre adresse e-mail.",
    })
    .email({ message: "Veuillez saisir une adresse e-mail valide." }),
  mobile: z
    .string({
      message: "Veuillez saisir votre numéro de mobile.",
    })
    .transform((input) => input.replace(/\D+/g, "")) // Remove all non-digit characters
    .superRefine((value, ctx) => {
      // Check for exact length and correct prefixes
      if (
        value.length !== 10 ||
        !(value.startsWith("06") || value.startsWith("07"))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Le numéro de mobile doit commencer par 06 ou 07 et doit comporter 10 chiffres.",
        });
      }

      // Check for non-digit characters
      if (!/^\d+$/.test(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Le numéro de mobile ne doit contenir que des chiffres.",
        });
      }
    }),
  dateOfBirth: z
    .string({
      message: "Veuillez saisir votre date de naissance au format JJ/MM/AAAA.",
    })
    .superRefine((val, ctx) => {
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "La date de naissance doit être au format JJ/MM/AAAA.",
        });
      } else if (!validateDateOfBirth(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "La date de naissance ne peut être dans le futur ou irréaliste.",
        });
      }
    }),
  voie: z.string({
    message: "Veuillez saisir votre adresse.",
  }),
  codePostal: z
    .string({ message: "Veuillez saisir votre code postal." })
    .superRefine((val, ctx) => {
      if (!/^\d+$/.test(val) || val.length !== 5) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Le code postal doit uniquement contenir des chiffres.",
        });
      }
    }),
  ville: z.string({
    message: "Veuillez saisir votre ville.",
  }),
});
