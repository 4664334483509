import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import { cn } from "src/lib/utils";

interface Props {
  question: string;
  onChange: () => void;
}

export const HolderForm = ({ question, onChange }: Props) => {
  const [selected, setSelected] = useState<string | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
    onChange();
  };

  return (
    <div className="ease-in-out duration-200 transition-all">
      <h2 className="text-lg lg:text-xl font-bold pb-4">{question}</h2>
      <div className="pl-2 pb-4 flex justify-start">
        <FormControl>
          <RadioGroup
            row
            name="confirmation"
            value={selected}
            onChange={handleChange}
          >
            <FormControlLabel
              value="oui"
              control={
                <Radio
                  className={cn(selected === "oui" ? "!text-[#14708A]" : "")}
                />
              }
              className="mr-2"
              label={
                <span style={{ font: "Avenir", fontWeight: 500 }}>Oui</span>
              }
            />
            <FormControlLabel
              value="non"
              control={
                <Radio
                  className={cn(selected === "non" ? "!text-[#14708A]" : "")}
                />
              }
              className="mr-2"
              label={
                <span style={{ font: "Avenir", fontWeight: 500 }}>Non</span>
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};
