import { InactiveHeader } from "src/components/inactive-header";
import { ResponsiveChatbot } from "src/components/responsive/reponsive-chatbot";
import { DescriptionHeader } from "src/components/ui/description-header";
import { OffresFooter } from "../../offres-footer";
import { HolderForm } from "src/components/pricing/holder-check/holder-form";
import { useEffect, useState } from "react";
import { MuiInput } from "src/components/ui/mui-input";
import { Button } from "src/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { z } from "zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUserStore } from "src/zustand/userStore";
import { Helmet } from "react-helmet";
import {
  getMaxAgeByPlan,
  getMinAgeByPlan,
  getProductNameById,
  getProductTitleByPriceId,
} from "src/lib/utils";
import { BasePricingSchema } from "src/schemas/pricing/BasePricingSchema";

// Utility function to get question text based on the plan
const getQuestionText = (plan: string, type: string) => {
  switch (type) {
    case "holder":
      switch (plan) {
        case "Pass Rail":
          return "Serez-vous le titulaire du Pass Rail ?*";
        default:
          return "Serez-vous le titulaire de la Carte ?*";
      }
    case "renewal":
      switch (plan) {
        case "Pass Rail":
          return "S’agit-il d’un renouvellement du Pass Rail ?*";
        default:
          return "S’agit-il d’un renouvellement de Carte ?*";
      }
    case "dobTitle":
      switch (plan) {
        case "Pass Rail":
          return "Date de naissance du titulaire du Pass Rail*";
        default:
          return "Date de naissance du titulaire de la Carte*";
      }
    default:
      return "";
  }
};

// The main component
export const BasePricingView = () => {
  const [questionsAnswered, setQuestionsAnswered] = useState<string[]>([]);
  const [dob, setDob] = useState("");
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const store = useUserStore();
  const planId = searchParams.get("productId");
  const plan = getProductNameById(planId ?? "");

  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPending(false);
    }, 1000);
  }, []);

  if (!planId || !plan) {
    navigate("/app/offre");
  }

  const minAge = getMinAgeByPlan(plan);
  const maxAge = getMaxAgeByPlan(plan);

  const form = useForm<z.infer<typeof BasePricingSchema>>({
    resolver: zodResolver(BasePricingSchema),
    defaultValues: {
      dateOfBirth: "",
      minAge,
      maxAge,
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let formattedDate = value;
    formattedDate = formattedDate.replace(/\D+/g, "");
    if (formattedDate.length <= 2) {
      formattedDate = formattedDate;
    } else if (formattedDate.length <= 4) {
      formattedDate = `${formattedDate.slice(0, 2)}/${formattedDate.slice(2)}`;
    } else {
      formattedDate = `${formattedDate.slice(0, 2)}/${formattedDate.slice(
        2,
        4
      )}/${formattedDate.slice(4, 8)}`;
    }
    setDob(formattedDate);
  };

  const onSubmit = (values: z.infer<typeof BasePricingSchema>) => {
    setIsPending(true);
    setTimeout(() => {
      store.setSelectedPlanHeader(getProductTitleByPriceId[planId ?? ""]);
      store.setDob(values.dateOfBirth);
      setIsPending(false);
      navigate("/app/offre/pricing/sncf-step3");
    }, 2000); //Adjust submitting loader time as needed
  };

  if (isPending) {
    return (
      <>
        <Helmet>
          <title>SNCF Connect - Offres - Carte SNCF - Etape 1</title>
        </Helmet>
        <InactiveHeader />
        <DescriptionHeader title={store.selectedPlanHeader} />
        <div className="py-[40px] lg:py-[50px] px-[32px] w-full max-w-[700px] mx-auto min-h-[80dvh] lg:min-h-[50dvh]">
          <div className="mx-auto flex flex-col items-center">
            <h3 className="font-bold text-xl">Chargement…</h3>
            <img src="/better-lodd.gif" alt="loader" className="w-[70px]" />
          </div>
        </div>
        <ResponsiveChatbot />
        <OffresFooter />
      </>
    );
  }


  return (
    <>
      <Helmet>
        <title>SNCF Connect - Offres - Carte SNCF - Etape 1</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionHeader title={store.selectedPlanHeader} />
      <div className="py-[40px] lg:py-[50px] px-[32px] w-full max-w-[700px] mx-auto min-h-[80dvh] lg:min-h-[50dvh]">
        <p className="text-[#5e6878] text-[0.8125rem] mb-8 tracking-wide font-extralight">
          *champs obligatoires
        </p>
        <HolderForm
          question={getQuestionText(plan, "holder")}
          onChange={() => setQuestionsAnswered((prev) => [...prev, "titulaire"])}
        />
        {questionsAnswered.length > 0 && (
          <HolderForm
            question={getQuestionText(plan, "renewal")}
            onChange={() => setQuestionsAnswered((prev) => [...prev, "renewal"])}
          />
        )}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {questionsAnswered.length > 1 && (
              <>
                <h2 className="text-lg lg:text-xl font-bold pb-4">
                  {getQuestionText(plan, "dobTitle")}
                </h2>
                <FormField
                  control={form.control}
                  name="dateOfBirth"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <MuiInput
                          {...field}
                          label="Date de naissance (JJ/MM/AAAA)*"
                          className="w-full"
                          error={!!form.formState.errors.dateOfBirth}
                          value={dob}
                          onChange={(e) => {
                            handleChange(e);
                            field.onChange(e);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            {dob.length === 10 && (
              <div className="flex justify-center mt-6">
                <Button className="w-[100px] mx-auto" type="submit">
                  Continuer
                </Button>
              </div>
            )}
          </form>
        </Form>
      </div>
      <ResponsiveChatbot />
      <OffresFooter />
    </>
  );
};
