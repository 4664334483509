import { OffresDesktopFooter } from "src/components/ui/offres-desktop-footer";
import { OffresMobileFooter } from "src/components/ui/offres-mobile-footer";

export const OffresFooter = () => {
  return (
    <>
      <OffresMobileFooter />
      <OffresDesktopFooter />
    </>
  );
};
