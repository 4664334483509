import React, { useRef } from "react";
import { DesktopDescriptionBanner } from "../ui/desktop-description-banner";
import { MobileDescriptionBanner } from "../ui/mobile-description-banner";
import { DescriptionHeader } from "../ui/description-header";
import { useInView } from "framer-motion";

interface DescriptionBannerProps {
  headerTitle: string;
  desktopImage: string;
  mobileImage: string;
  cardTitle: string;
  cardDescription: string;
  price: string;
  buttonLabel: string;
  floatingDescription?: string;
  href: string;
  isStickyVisible?: boolean;
  badge?: string;
  badgeColor?: string;
}

export const DescriptionBanner: React.FC<DescriptionBannerProps> = ({
  headerTitle,
  desktopImage,
  mobileImage,
  cardTitle,
  cardDescription,
  price,
  buttonLabel,
  href,
  isStickyVisible,
  floatingDescription,
  badge,
  badgeColor,
}) => {
  const desktopRef = useRef<HTMLDivElement>(null);
  const mobileRef = useRef<HTMLDivElement>(null);

  const isInViewDesktop = useInView(desktopRef);
  const isInViewMobile = useInView(mobileRef);

  return (
    <>
      <DescriptionHeader
        href={href}
        price={price}
        description={floatingDescription}
        isStickyVisible={isStickyVisible}
        isInView={isInViewDesktop || isInViewMobile}
        title={headerTitle}
      />

      <DesktopDescriptionBanner
        image={desktopImage}
        cardTitle={cardTitle}
        cardDescription={cardDescription}
        price={price}
        badge={badge}
        ref={desktopRef}
        badgeColor={badgeColor}
        buttonLabel={buttonLabel}
        href={href}
      />
      <MobileDescriptionBanner
        image={mobileImage}
        cardTitle={cardTitle}
        cardDescription={cardDescription}
        price={price}
        ref={mobileRef}
        badgeColor={badgeColor}
        badge={badge}
        buttonLabel={buttonLabel}
        href={href}
      />
    </>
  );
};
