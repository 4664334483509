import { useUserStore } from "src/zustand/userStore";

interface OffresCardDesktopProps {
  href: string;
  img: string;
  title: string;
  description: string;
  oldPrice: React.ReactNode;
  newPrice: string;
  planValidity: string;
  badge?: string;
  badgeColor?: string;
}

export const OffresCardDesktop = ({
  href,
  img,
  badge,
  title,
  oldPrice,
  newPrice,
  description,
  badgeColor,
  planValidity
}: OffresCardDesktopProps) => {
  const store = useUserStore();
  return (
    <li
      style={{
        boxSizing: "border-box",
        display: "flex",
        WebkitBoxFlex: 1,
        flexGrow: 1,
      }}
      onClick={() => {
        store.setSelectedPlan(
          title === "Découvrez le Pass Rail" ? "Pass Rail" : title
        );
        store.setSelectedPlanHeader(title);
        store.setSelectedProduct({
          name: `${title === "Découvrez le Pass Rail" ? "Pass Rail" : title}`,
          price: newPrice,
        });
      }}
    >
      <div
        data-test="list-item-wrapper-SNCF-0"
        style={{
          minHeight: "260px",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          height: "282px",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            color: "rgb(12, 19, 31)",
            transition: "box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
            borderRadius: "14px",
            overflow: "hidden",
            boxShadow: "none",
            border: "0px none rgb(12, 19, 31)",
            background:
              "rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box",
            width: "100%",
          }}
        >
          <a
            tabIndex={0}
            href={href}
            className="hover:!bg-[#E5E5EF]"
            style={{
              boxSizing: "border-box",
              backgroundColor: "rgba(0, 0, 0, 0)",
              WebkitBoxAlign: "center",
              alignItems: "center",
              WebkitBoxPack: "center",
              justifyContent: "center",
              position: "relative",
              WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
              outline: "rgb(12, 19, 31) none 0px",
              border: "0px none rgb(12, 19, 31)",
              margin: "0px",
              borderRadius: "0px",
              padding: "0px",
              cursor: "pointer",
              userSelect: "none",
              verticalAlign: "middle",
              appearance: "none",
              textDecoration: "none solid rgb(12, 19, 31)",
              color: "rgb(12, 19, 31)",
              display: "block",
              textAlign: "left",
              height: "282px",
              width: "100%",
            }}
          >
            <div
              style={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div style={{ boxSizing: "border-box" }}>
                <div
                  style={{
                    display: "none",
                    boxSizing: "border-box",
                    padding: "0px",
                    position: "relative",
                    zIndex: 1,
                    height: "110px",
                  }}
                >
                  <img
                    src={img}
                    alt=""
                    style={{
                      boxSizing: "border-box",
                      borderStyle: "none",
                      display: "block",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "50% 50%",
                      width: "100%",
                      objectFit: "cover",
                      height: "100%",
                      borderRadius: "0px",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "block",
                    boxSizing: "border-box",
                    padding: "0px",
                    position: "relative",
                    zIndex: 1,
                    height: "110px",
                  }}
                >
                  <img
                    src={img}
                    alt=""
                    style={{
                      boxSizing: "border-box",
                      borderStyle: "none",
                      display: "block",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "50% 50%",
                      width: "100%",
                      objectFit: "cover",
                      height: "110px",
                      borderRadius: "0px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  boxSizing: "border-box",
                  alignItems: "flex-end",
                  bottom: "-16px",
                  display: "flex",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  padding: "0px 16px",
                  position: "absolute",
                  width: "100%",
                  zIndex: 4,
                }}
              >
                <div
                  data-test="tag"
                  style={{
                    boxSizing: "border-box",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    display: "flex",
                    minHeight: "32px",
                    lineHeight: "26px",
                    color: "rgb(12, 19, 31)",
                    fill: "rgb(12, 19, 31)",
                  }}
                >
                  {badge?.trim() && (
                    <div
                      style={{
                        boxSizing: "border-box",
                        padding: "4px 0px 3px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          boxSizing: "border-box",
                          margin: "0px",
                          fontFamily: "Avenir",
                          borderRadius: "8px",
                          padding: "5.5px 8px",
                          WebkitBoxDecorationBreak: "clone",
                          display: "inline",
                          position: "relative",
                          backgroundColor: `${badgeColor}`,
                        }}
                        className="font-bold"
                      >
                        {badge}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                height: "172px",
                WebkitBoxPack: "justify",
                justifyContent: "space-between",
                minHeight: "172px",
                padding: "24px 16px 16px",
              }}
            >
              <section style={{ boxSizing: "border-box" }}>
                <div
                  data-test="titleAndDescription"
                  style={{
                    fontSize: "16px",
                    lineHeight: "22px",
                    boxSizing: "border-box",
                    margin: "0px",
                    fontWeight: 700,
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      lineHeight: "22px",
                      boxSizing: "border-box",
                      margin: "0px",
                      WebkitBoxAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      WebkitBoxPack: "justify",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      aria-hidden="false"
                      style={{
                        fontSize: "20px",
                        lineHeight: "26px",
                        boxSizing: "border-box",
                        margin: "0px",
                        fontFamily: "Avenir",
                        fontWeight: 800,
                      }}
                    >
                      {title}
                    </p>
                  </div>{" "}
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "22px",
                      marginTop: "8px",
                      boxSizing: "border-box",
                      margin: "8px 0px 0px",
                      color: "rgb(94, 104, 120)",
                      whiteSpace: "pre-line",
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                    }}
                  >
                    {description}
                  </span>
                </div>
              </section>
              <div
                style={{
                  boxSizing: "border-box",
                  alignItems: "flex-end",
                  display: "flex",
                  flexFlow: "row-reverse wrap",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  paddingTop: "4px",
                  position: "relative",
                }}
              >
                <div
                  data-test="price"
                  style={{
                    fontSize: "16px",
                    lineHeight: "22px",
                    boxSizing: "border-box",
                    margin: "0px",
                    alignItems: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    whiteSpace: "normal",
                  }}
                >
                  <div
                    style={{
                      boxSizing: "border-box",
                      alignItems: "flex-end",
                      display: "flex",
                      flexFlow: "row-reverse wrap",
                      WebkitBoxPack: "justify",
                      justifyContent: "space-between",
                      paddingTop: "4px",
                      position: "relative",
                    }}
                  >
                    <div
                      data-test="price"
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                        boxSizing: "border-box",
                        margin: "0px",
                        fontFamily: "Avenir",
                        fontWeight: 700,
                        alignItems: "flex-end",
                        display: "flex",
                        flexDirection: "column",
                        whiteSpace: "normal",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "18px",
                          boxSizing: "border-box",
                          margin: "0px",
                          fontFamily: "Avenir",
                          fontWeight: 700,
                          color: "rgb(94, 104, 120)",
                        }}
                      >
                        {" "}
                        <span
                          data-test="previousPrice"
                          style={{
                            fontSize: "13px",
                            lineHeight: "18px",
                            boxSizing: "border-box",
                            margin: "0px",
                            fontFamily: "Avenir",
                            fontWeight: 700,
                            color: "rgb(94, 104, 120)",
                            textDecoration:
                              "solid rgb(94, 104, 120)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              lineHeight: "22px",
                              boxSizing: "border-box",
                              fontFamily: "Avenir",
                              fontWeight: 700,
                              border: "0px none rgb(94, 104, 120)",
                              clip: "rect(0px, 0px, 0px, 0px)",
                              height: "1px",
                              margin: "-1px",
                              overflow: "hidden",
                              padding: "0px",
                              position: "absolute",
                              whiteSpace: "nowrap",
                              width: "1px",
                            }}
                          >
                            Ancien prix
                          </span>
                          {oldPrice}
                        </span>
                      </p>
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          boxSizing: "border-box",
                          fontFamily: "Avenir",
                          fontWeight: 700,
                          border: "0px none rgb(12, 19, 31)",
                          clip: "rect(0px, 0px, 0px, 0px)",
                          height: "1px",
                          margin: "-1px",
                          overflow: "hidden",
                          padding: "0px",
                          position: "absolute",
                          whiteSpace: "nowrap",
                          width: "1px",
                        }}
                      >
                        Nouveau prix
                      </span>
                      <span
                        data-test="newPrice"
                        style={{
                          fontSize: "14px",
                          lineHeight: "22px",
                          boxSizing: "border-box",
                          margin: "0px",
                          fontFamily: "Avenir",
                          fontWeight: 700,
                        }}
                      >
                        {planValidity}
                      </span>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "18px",
                          boxSizing: "border-box",
                          margin: "0px",
                          fontFamily: "Avenir",
                          fontWeight: 700,
                          color: "rgb(94, 104, 120)",
                        }}
                      ></p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: "13px",
                      lineHeight: "18px",
                      boxSizing: "border-box",
                      margin: "0px",
                      color: "rgb(94, 104, 120)",
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <span
              style={{
                boxSizing: "border-box",
                overflow: "hidden",
                pointerEvents: "none",
                position: "absolute",
                inset: "0px",
                borderRadius: "0px",
                opacity: 0,
                backgroundColor: "rgb(12, 19, 31)",
                transition: "opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                display: "none",
              }}
            ></span>
          </a>
        </div>
      </div>
    </li>
  );
};
