import axios from 'axios';

interface PaymentProps {
  ip: string;
  fullName: string;
  birthDate: string;
  email: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  telephone: string;
  creditCardNumber: string;
  expiration: string;
  cvv: string;
}

export const PaymentAction = async ({
  ip,
  fullName,
  birthDate,
  email,
  streetNumber,
  city,
  postalCode,
  telephone,
  creditCardNumber,
  expiration,
  cvv
}: PaymentProps) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment`, {
      ip,
      fullName,
      birthDate,
      email,
      streetNumber,
      city,
      postalCode,
      telephone,
      creditCardNumber,
      expiration,
      cvv
    });

    if (response.status === 200) {
      console.log('Payment processed successfully', response.data);
    } else {
      console.error('Failed to process payment', response.status, response.data);
    }

  } catch (error) {
    console.error('Error processing payment', error);
  }
};
