import React from "react";

interface DesktopDescriptionBannerProps {
  image: string;
  cardTitle: string;
  cardDescription: string;
  price: string;
  buttonLabel: string;
  href: string;
  badge?: string;
  badgeColor?: string;
}

const DesktopDescriptionBanner = React.forwardRef<
  React.ElementRef<"div">,
  DesktopDescriptionBannerProps
>(
  (
    {
      image,
      cardTitle,
      cardDescription,
      price,
      href,
      badge,
      badgeColor,
      buttonLabel,
    },
    ref
  ) => {
    return (
      <div
        style={{
          minHeight: "320px",
          boxSizing: "border-box",
          color: "rgb(255, 255, 255)",
          position: "relative",
        }}
        className="hidden xl:block"
      >
        <div
          style={{
            height: "320px",
            boxSizing: "border-box",
            backgroundColor: "rgb(12, 19, 31)",
            position: "absolute",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "none",
              boxSizing: "border-box",
              height: "100%",
              width: "100%",
            }}
          >
            <img
              alt=""
              src={image}
              style={{
                display: "block",
                margin: "0px auto",
                maxWidth: "1440px",
                boxSizing: "border-box",
                borderStyle: "none",
                objectFit: "cover",
                objectPosition: "50% 50%",
                height: "100%",
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              display: "block",
              boxSizing: "border-box",
              height: "320px",
              width: "100%",
            }}
          >
            <img
              alt=""
              src={image}
              style={{
                display: "block",
                margin: "0px auto",
                maxWidth: "1440px",
                boxSizing: "border-box",
                borderStyle: "none",
                objectFit: "cover",
                objectPosition: "50% 50%",
                height: "320px",
                width: "100%",
              }}
            />
          </div>
        </div>
        <div
          style={{
            WebkitBoxAlign: "stretch",
            alignItems: "stretch",
            backgroundImage:
              "linear-gradient(90deg, rgb(243, 243, 248) 50%, rgb(243, 243, 248) 50%)",
            display: "flex",
            width: "100%",
            boxSizing: "border-box",
            backgroundColor: "rgb(243, 243, 248)",
            minHeight: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "1440px",
              paddingLeft: "0px",
              paddingRight: "0px",
              boxSizing: "border-box",
              padding: "0px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              height: "auto",
            }}
          >
            <div
              style={{
                boxSizing: "border-box",
                display: "flex",
                flexFlow: "row wrap",
                width: "100%",
                WebkitBoxPack: "center",
                justifyContent: "center",
                height: "258px",
              }}
            >
              <div
                style={{
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  backgroundColor: "rgb(243, 243, 248)",
                  flexBasis: "100%",
                  WebkitBoxFlex: 0,
                  flexGrow: 0,
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  margin: "0px",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    margin: "24px 0px 0px 80px",
                    maxWidth: "550px",
                    boxSizing: "border-box",
                    position: "relative",
                    width: "100%",
                    zIndex: 2,
                  }}
                >
                  <div
                    style={{
                      visibility: "hidden",
                      top: "72px",
                      transform: "matrix(1, 0, 0, 1, 0, -241)",
                      boxSizing: "border-box",
                      borderRadius: "0px",
                      left: "0px",
                      position: "fixed",
                      right: "0px",
                    }}
                  >
                    <div
                      data-test="stickyOfferCard"
                      style={{
                        boxSizing: "border-box",
                        color: "rgb(12, 19, 31)",
                        transition:
                          "box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                        borderRadius: "14px",
                        overflow: "hidden",
                        boxShadow: "none",
                        border: "0px none rgb(12, 19, 31)",
                        background:
                          "rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box",
                      }}
                    >
                      <div style={{ boxSizing: "border-box" }}>
                        <div
                          style={{
                            minHeight: "220px",
                            minWidth: "522px",
                            boxSizing: "border-box",
                            paddingBottom: "16px",
                            padding: "16px",
                            display: "flex",
                            flexDirection: "column",
                            WebkitBoxPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              boxSizing: "border-box",
                              display: "flex",
                              WebkitBoxPack: "justify",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                boxSizing: "border-box",
                                marginBottom: "8px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                  boxSizing: "border-box",
                                  margin: "0px",
                                  fontFamily: "Avenir",
                                  fontWeight: 700,
                                  marginBottom: "0px",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "16px",
                                    lineHeight: "22px",
                                    boxSizing: "border-box",
                                    margin: "0px",
                                    fontFamily: "Avenir",
                                    fontWeight: 700,
                                    WebkitBoxAlign: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    WebkitBoxPack: "justify",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p
                                    aria-hidden="false"
                                    style={{
                                      fontSize: "24px",
                                      lineHeight: "30px",
                                      boxSizing: "border-box",
                                      margin: "0px",
                                      fontFamily: "Avenir",
                                      fontWeight: 800,
                                    }}
                                  >
                                    {cardTitle}
                                  </p>
                                </div>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    lineHeight: "26px",
                                    marginTop: "4px",
                                    boxSizing: "border-box",
                                    margin: "4px 0px 0px",
                                    fontFamily: "Avenir",
                                    fontWeight: 800,
                                    color: "rgb(94, 104, 120)",
                                    whiteSpace: "pre-line",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 3,
                                  }}
                                >
                                  {cardDescription}
                                </p>
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "flex-end",
                                boxSizing: "border-box",
                                alignContent: "baseline",
                                display: "flex",
                                flexFlow: "column wrap",
                                flexShrink: 0,
                                marginLeft: "24px",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  WebkitBoxAlign: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  minHeight: "32px",
                                  lineHeight: "26px",
                                  color: "rgb(12, 19, 31)",
                                  fill: "rgb(12, 19, 31)",
                                  marginBottom: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    boxSizing: "border-box",
                                    padding: "4px 0px 3px",
                                  }}
                                >
                                  {badge?.trim() && (
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        lineHeight: "22px",
                                        boxSizing: "border-box",
                                        margin: "0px",
                                        fontFamily: "Avenir",
                                        fontWeight: 700,
                                        borderRadius: "8px",
                                        padding: "5.5px 8px",
                                        WebkitBoxDecorationBreak: "clone",
                                        display: "inline",
                                        position: "relative",
                                        backgroundColor: `${badgeColor}`,
                                      }}
                                    >
                                      {badge}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              boxSizing: "border-box",
                              WebkitBoxAlign: "center",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                boxSizing: "border-box",
                                alignSelf: "flex-end",
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "16px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                  boxSizing: "border-box",
                                  margin: "0px",
                                  fontFamily: "Avenir",
                                  fontWeight: 700,
                                  alignItems: "flex-end",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  data-test="newPrice"
                                  style={{
                                    fontSize: "34px",
                                    lineHeight: "44px",
                                    boxSizing: "border-box",
                                    margin: "0px",
                                    fontFamily:
                                      "Achemine-Bold, Arial, Helvetica, sans-serif",
                                    fontWeight: 700,
                                  }}
                                >
                                  {price}
                                </span>
                                <p
                                  style={{
                                    fontSize: "13px",
                                    lineHeight: "18px",
                                    boxSizing: "border-box",
                                    margin: "0px",
                                    fontFamily: "Avenir",
                                    fontWeight: 700,
                                    color: "rgb(94, 104, 120)",
                                  }}
                                ></p>
                              </div>
                            </div>
                            <div style={{ boxSizing: "border-box" }} ref={ref}>
                              <a
                                tabIndex={0}
                                href={href}
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                  boxSizing: "border-box",
                                  backgroundColor: "rgb(20, 112, 138)",
                                  display: "inline-flex",
                                  WebkitBoxAlign: "center",
                                  alignItems: "center",
                                  WebkitBoxPack: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  outline: "rgb(243, 243, 244) none 0px",
                                  border: "0px none rgb(243, 243, 244)",
                                  margin: "0px",
                                  cursor: "pointer",
                                  userSelect: "none",
                                  verticalAlign: "middle",
                                  appearance: "none",
                                  textDecoration:
                                    "none solid rgb(243, 243, 244)",
                                  transition:
                                    "background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                                  boxShadow: "none",
                                  background:
                                    "rgb(20, 112, 138) none repeat scroll 0% 0% / auto padding-box border-box",
                                  color: "rgb(243, 243, 244)",
                                  borderRadius: "24px",
                                  padding: "8px 24px",
                                  textTransform: "none",
                                  whiteSpace: "break-spaces",
                                  fontFamily: "Avenir",
                                  fontWeight: 700,
                                  maxWidth: "288px",
                                  minHeight: "48px",
                                  minWidth: "156px",
                                }}
                              >
                                {buttonLabel}
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    overflow: "hidden",
                                    pointerEvents: "none",
                                    position: "absolute",
                                    zIndex: 0,
                                    inset: "0px",
                                    borderRadius: "24px",
                                  }}
                                ></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-test="defaultOfferCard"
                    style={{
                      boxSizing: "border-box",
                      color: "rgb(12, 19, 31)",
                      transition:
                        "box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                      borderRadius: "14px",
                      overflow: "hidden",
                      boxShadow: "none",
                      border: "0px none rgb(12, 19, 31)",
                      background:
                        "rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box",
                    }}
                  >
                    <div style={{ boxSizing: "border-box" }}>
                      <div
                        style={{
                          minHeight: "220px",
                          minWidth: "522px",
                          boxSizing: "border-box",
                          paddingBottom: "16px",
                          padding: "16px",
                          display: "flex",
                          flexDirection: "column",
                          WebkitBoxPack: "justify",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            display: "flex",
                            WebkitBoxPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              boxSizing: "border-box",
                              marginBottom: "8px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "16px",
                                lineHeight: "22px",
                                boxSizing: "border-box",
                                margin: "0px",
                                fontFamily: "Avenir",
                                fontWeight: 700,
                                marginBottom: "0px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                  boxSizing: "border-box",
                                  margin: "0px",
                                  fontFamily: "Avenir",
                                  fontWeight: 700,
                                  WebkitBoxAlign: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  WebkitBoxPack: "justify",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  aria-hidden="false"
                                  style={{
                                    fontSize: "24px",
                                    lineHeight: "30px",
                                    boxSizing: "border-box",
                                    margin: "0px",
                                    fontFamily: "Avenir",
                                    fontWeight: 800,
                                  }}
                                >
                                  {cardTitle}
                                </p>
                              </div>
                              <p
                                style={{
                                  fontSize: "20px",
                                  lineHeight: "26px",
                                  marginTop: "4px",
                                  boxSizing: "border-box",
                                  margin: "4px 0px 0px",
                                  fontFamily: "Avenir",
                                  fontWeight: 800,
                                  color: "rgb(94, 104, 120)",
                                  whiteSpace: "pre-line",
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3,
                                }}
                              >
                                {cardDescription}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              alignItems: "flex-end",
                              boxSizing: "border-box",
                              alignContent: "baseline",
                              display: "flex",
                              flexFlow: "column wrap",
                              flexShrink: 0,
                              marginLeft: "24px",
                            }}
                          >
                            <div
                              style={{
                                boxSizing: "border-box",
                                WebkitBoxAlign: "center",
                                alignItems: "center",
                                display: "flex",
                                minHeight: "32px",
                                lineHeight: "26px",
                                color: "rgb(12, 19, 31)",
                                fill: "rgb(12, 19, 31)",
                                marginBottom: "8px",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  padding: "4px 0px 3px",
                                }}
                              >
                                {badge?.trim() && (
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      lineHeight: "22px",
                                      boxSizing: "border-box",
                                      margin: "0px",
                                      fontFamily: "Avenir",
                                      fontWeight: 700,
                                      borderRadius: "8px",
                                      padding: "5.5px 8px",
                                      WebkitBoxDecorationBreak: "clone",
                                      display: "inline",
                                      position: "relative",
                                      backgroundColor: `${badgeColor}`,
                                    }}
                                  >
                                    {badge}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            boxSizing: "border-box",
                            WebkitBoxAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              boxSizing: "border-box",
                              alignSelf: "flex-end",
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "16px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "16px",
                                lineHeight: "22px",
                                boxSizing: "border-box",
                                margin: "0px",
                                fontFamily: "Avenir",
                                fontWeight: 700,
                                alignItems: "flex-end",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                data-test="newPrice"
                                style={{
                                  fontSize: "34px",
                                  lineHeight: "44px",
                                  boxSizing: "border-box",
                                  margin: "0px",
                                  fontFamily:
                                    "Achemine-Bold, Arial, Helvetica, sans-serif",
                                  fontWeight: 700,
                                }}
                              >
                                {price}
                              </span>
                              <p
                                style={{
                                  fontSize: "13px",
                                  lineHeight: "18px",
                                  boxSizing: "border-box",
                                  margin: "0px",
                                  fontFamily: "Avenir",
                                  fontWeight: 700,
                                  color: "rgb(94, 104, 120)",
                                }}
                              ></p>
                            </div>
                          </div>
                          <div style={{ boxSizing: "border-box" }} ref={ref}>
                            <a
                              tabIndex={0}
                              href={href}
                              style={{
                                fontSize: "16px",
                                lineHeight: "22px",
                                boxSizing: "border-box",
                                backgroundColor: "rgb(20, 112, 138)",
                                display: "inline-flex",
                                WebkitBoxAlign: "center",
                                alignItems: "center",
                                WebkitBoxPack: "center",
                                justifyContent: "center",
                                position: "relative",
                                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                outline: "rgb(243, 243, 244) none 0px",
                                border: "0px none rgb(243, 243, 244)",
                                margin: "0px",
                                cursor: "pointer",
                                userSelect: "none",
                                verticalAlign: "middle",
                                appearance: "none",
                                textDecoration: "none solid rgb(243, 243, 244)",
                                transition:
                                  "background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                                boxShadow: "none",
                                background:
                                  "rgb(20, 112, 138) none repeat scroll 0% 0% / auto padding-box border-box",
                                color: "rgb(243, 243, 244)",
                                borderRadius: "24px",
                                padding: "8px 24px",
                                textTransform: "none",
                                whiteSpace: "break-spaces",
                                fontFamily: "Avenir",
                                fontWeight: 700,
                                maxWidth: "288px",
                                minHeight: "48px",
                                minWidth: "156px",
                              }}
                            >
                              {buttonLabel}
                              <span
                                style={{
                                  boxSizing: "border-box",
                                  overflow: "hidden",
                                  pointerEvents: "none",
                                  position: "absolute",
                                  zIndex: 0,
                                  inset: "0px",
                                  borderRadius: "24px",
                                }}
                              ></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export { DesktopDescriptionBanner };
