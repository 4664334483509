import { InactiveHeader } from "src/components/inactive-header";
import { DescriptionHeader } from "src/components/ui/description-header";
import { useUserStore } from "src/zustand/userStore";
import { InformationsMain } from "./informations-main";
import { ChatbotSection } from "../../chatbot-section";
import { OffresFooter } from "../../offres-footer";
import { Helmet } from "react-helmet";

export const Informations = () => {
  const store = useUserStore();
  return (
    <>
      <Helmet>
        <title>SNCF Connect - Offres - Carte SNCF - Etape 3</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionHeader title={store.selectedPlanHeader} />
      <div className="max-w-[720px] mx-auto p-2">
        <InformationsMain />
      </div>
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
