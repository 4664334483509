import { Button } from "src/components/ui/button";
import { OffresCardDesktop } from "src/components/ui/offres-card-desktop";
import { Offer } from "src/screens/app/catalogue/offres-main";
interface Props {
  offers: Offer[];
}
export const OffresMainDesktop = ({ offers }: Props) => {
  return (
    <div
      style={{
        WebkitBoxAlign: "stretch",
        alignItems: "stretch",
        backgroundImage:
          "linear-gradient(90deg, rgb(243, 243, 248) 50%, rgb(243, 243, 248) 50%)",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "rgb(243, 243, 248)",
        minHeight: "100%",
      }}
      className="hidden xl:flex"
    >
      <div
        style={{
          maxWidth: "1440px",
          paddingLeft: "0px",
          paddingRight: "0px",
          boxSizing: "border-box",
          padding: "0px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          height: "auto",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            flexFlow: "row wrap",
            width: "100%",
            WebkitBoxPack: "center",
            justifyContent: "center",
            height: "854px",
          }}
        >
          <div
            style={{
              paddingLeft: "80px",
              paddingRight: "80px",
              backgroundColor: "rgb(243, 243, 248)",
              flexBasis: "100%",
              WebkitBoxFlex: 0,
              flexGrow: 0,
              maxWidth: "100%",
              boxSizing: "border-box",
              margin: "0px",
              flexDirection: "row",
            }}
          >
            <div
              data-test="breadcrumb"
              style={{
                fontSize: "16px",
                lineHeight: "22px",
                boxSizing: "border-box",
                margin: "24px 0px 0px",
                color: "rgb(94, 104, 120)",
                padding: "0px",
              }}
            >
              <ol
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxAlign: "center",
                  alignItems: "center",
                  padding: "0px",
                  margin: "0px",
                  listStyle: "outside none none",
                }}
              >
                <li style={{ boxSizing: "border-box" }}>
                  <a
                    href="/app"
                    style={{
                      fontSize: "13px",
                      lineHeight: "18px",
                      boxSizing: "border-box",
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      margin: "0px",
                      color: "rgb(20, 112, 138)",
                      textDecoration: "none solid rgb(20, 112, 138)",
                      cursor: "pointer",
                      position: "relative",
                      WebkitBoxAlign: "center",
                      alignItems: "center",
                      display: "inline-flex",
                      padding: "0px",
                    }}
                  >
                    Accueil
                  </a>
                </li>
                <li
                  aria-hidden="true"
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    userSelect: "none",
                    marginLeft: "8px",
                    marginRight: "8px",
                  }}
                >
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      boxSizing: "border-box",
                      height: "13px",
                      margin: "2px -8px 0px",
                      width: "1.25rem",
                    }}
                  >
                    <path
                      d="M8.79362 6.6659C8.36568 7.06175 8.36568 7.73825 8.79362 8.1341L12.973 12L8.79362 15.8659C8.36568 16.2617 8.36568 16.9383 8.79362 17.3341L8.83447 17.3719C9.21775 17.7264 9.80928 17.7264 10.1926 17.3719L15.2064 12.7341C15.6343 12.3383 15.6343 11.6617 15.2064 11.2659L10.1926 6.62811C9.80928 6.27358 9.21775 6.27358 8.83447 6.62811L8.79362 6.6659Z"
                      style={{ boxSizing: "border-box" }}
                    ></path>
                  </svg>
                </li>
                <li style={{ boxSizing: "border-box" }}>
                  <p
                    aria-current="page"
                    style={{
                      fontSize: "13px",
                      lineHeight: "18px",
                      boxSizing: "border-box",
                      margin: "2px 0px 0px",
                      color: "rgb(12, 19, 31)",
                    }}
                  >
                    Offres
                  </p>
                </li>
              </ol>
            </div>
            <h1
              style={{
                fontSize: "34px",
                lineHeight: "44px",
                boxSizing: "border-box",
                margin: "40px 0px 32px",
                fontFamily: "Achemine-Bold, Arial, Helvetica, sans-serif",
                fontWeight: 700,
              }}
            >
              Offres et services
            </h1>
            <div
              style={{
                WebkitBoxAlign: "center",
                alignItems: "center",
                boxSizing: "border-box",
                display: "flex",
              }}
            >
              <h2
                style={{
                  fontSize: "24px",
                  lineHeight: "30px",
                  boxSizing: "border-box",
                  margin: "0px",
                  fontFamily: "Avenir",
                  fontWeight: 800,
                  flex: "1 1 0%",
                }}
              >
                Cartes et abonnements SNCF
              </h2>
              <div style={{ boxSizing: "border-box", marginLeft: "16px" }} className="hidden">
                <Button variant="outline">Comparer les cartes et abos</Button>
              </div>
            </div>
            <div style={{ boxSizing: "border-box", margin: "0px 0px 24px" }}>
              <div style={{ boxSizing: "border-box", padding: "16px 0px" }}>
                <ul
                  style={{
                    gridTemplateColumns: "302px 302px 302px 302px",
                    display: "grid",
                    gap: "24px",
                    boxSizing: "border-box",
                    listStyle: "outside none none",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  {offers.map((offer) => (
                    <OffresCardDesktop key={offer.title} {...offer} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
