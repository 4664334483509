import { BrowserRouter } from "react-router-dom";
import { RouteManager } from "src/RouteManager";
import { ThemeProvider } from "@mui/material";
import { theme } from "./MuiSetup";
import { clarity } from "react-microsoft-clarity";

function App() {
  clarity.init("n5e0b9ihkq");

  // Identify the user
  clarity.identify("USER_ID", { userProperty: "value" });

  // Cookie consent
  clarity.consent();

  // Setup a custom tag
  clarity.setTag("key", "value");

  // Upgrade session
  clarity.upgrade("upgradeReason");

  // Check if Clarity has been initialized before calling its methods
  if (clarity.hasStarted()) {
    clarity.identify("USER_ID", { userProperty: "value" });
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <RouteManager />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
