import React from "react";
import { MobileChatbotWrap } from "../ui/mobile-chat-bot-wrap";
import { OffresDesktopChatbotWrap } from "../ui/desktop-chat-bot-wrap";

export const ResponsiveChatbot = () => {
  return (
    <>
      <MobileChatbotWrap />
      <OffresDesktopChatbotWrap />
    </>
  );
};
