import { InactiveHeader } from "src/components/inactive-header";
import { OffresFooter } from "../../offres-footer";
import { SeniorDesktop } from "./main-desktop";
import { SeniorMobile } from "./senior-mobile";
import { ChatbotSection } from "../../chatbot-section";
import { DescriptionBanner } from "src/components/responsive/description-banner";
import { PriceIds } from "src/lib/utils";
import { Helmet } from "react-helmet";

export const CarteAvantageSeniorView = () => {

  return (
    <>
      <Helmet>
        <title>Carte Avantage Senior - SNCF Connect</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionBanner
        headerTitle="Carte Avantage Senior"
        desktopImage="/avantage_senior.jpg"
        
        isStickyVisible
        mobileImage="https://imagedelivery.net/ogMRJiFkIuPcC6nnMct1Sg/f64f89aa-73c3-49c9-36a2-25b1e8342500/public"
        cardTitle="Carte Avantage Senior"
        badge="Promotion"
        badgeColor="#8de8fe"
        cardDescription="+ 60 ans"
        floatingDescription="(60 ans et +)"
        price="2,45 €"
        buttonLabel="Commander la carte"
        href={`/app/offre/pricing/sncf?productId=${PriceIds["carte-avantage-senior"]}`}
      />
      <SeniorDesktop />
      <SeniorMobile />
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
