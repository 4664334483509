import { InactiveHeader } from "src/components/inactive-header";
import { DescriptionHeader } from "src/components/ui/description-header";
import { OffresFooter } from "../catalogue/offres-footer";
import { PaymentForm } from "./payment-form";
import { PaymentPreview } from "./payment-preview";
import { useRef } from "react";
import { Misc } from "./misc";
import { Helmet } from "react-helmet";

export const PaiementView = () => {
  const paymentBtnRef = useRef<React.ElementRef<"button">>(null);

  const onClick = () => {
    paymentBtnRef.current?.click();
  };

  function getTimePlus30Minutes(): string {
    const now = new Date();
    const futureTime = new Date(now.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

    const hours = futureTime.getHours();
    const minutes = futureTime.getMinutes();

    // Pad single digit minutes with a leading zero
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    // Return the time in HH:MM format
    return `${hours}:${paddedMinutes}`;
  }

  return (
    <>
      <Helmet>
        <title>SNCF Connect - Paiement</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionHeader title="Paiement" />
      <div className="flex flex-col xl:flex-row max-w-[1300px] mx-auto py-5 px-[10px] gap-6">
        <div className="w-full xl:w-[70%]">
          <h1 className="text-xl font-bold py-3">Payez en toute sécurité</h1>
          <div className="p-8 bg-white rounded-xl">
            <p className="text-sm mb-4">*champs obligatoires</p>
            <PaymentForm ref={paymentBtnRef} />
          </div>
        </div>
        <div className="xl:w-[30%]">
          <div className="flex items-center">
            <svg
              aria-hidden="true"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="select-none inline-block text-2xl flex-shrink-0 mr-2 size-6 fill-[#ae4713]"
              focusable="false"
            >
              <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.2472 17.8334C13.0824 18.08 12.8481 18.2723 12.574 18.3858C12.2999 18.4993 11.9983 18.5291 11.7074 18.4712C11.4164 18.4133 11.1491 18.2704 10.9393 18.0607C10.7296 17.8509 10.5867 17.5836 10.5288 17.2926C10.4709 17.0017 10.5007 16.7001 10.6142 16.426C10.7277 16.1519 10.92 15.9176 11.1666 15.7528C11.4133 15.588 11.7033 15.5 12 15.5C12.3978 15.5 12.7794 15.658 13.0607 15.9393C13.342 16.2206 13.5 16.6022 13.5 17C13.5 17.2967 13.412 17.5867 13.2472 17.8334ZM11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V13C13 13.2652 12.8946 13.5196 12.7071 13.7071C12.5196 13.8946 12.2652 14 12 14C11.7348 14 11.4804 13.8946 11.2929 13.7071C11.1054 13.5196 11 13.2652 11 13V6C11 5.73478 11.1054 5.48043 11.2929 5.29289Z"></path>
            </svg>
            <h1 className="font-bold py-3">
              Vous avez jusqu’à {getTimePlus30Minutes()} pour payer
            </h1>
          </div>

          <PaymentPreview onClick={onClick} />
        </div>
      </div>
      <div className="flex flex-col xl:flex-row max-w-[1300px] mx-auto px-[10px] gap-3">
        <div className="w-full xl:w-[70%] pb-7">
          <Misc />
        </div>
        <div className="w-[30%]">&nbsp;</div>
      </div>
      <OffresFooter />
    </>
  );
};
