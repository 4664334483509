import { OffresDesktopChatbotWrap } from "src/components/ui/desktop-chat-bot-wrap";
import { MobileChatbotWrap } from "src/components/ui/mobile-chat-bot-wrap";

export const ChatbotSection = () => {
  return (
    <>
      <MobileChatbotWrap />
      <OffresDesktopChatbotWrap />
    </>
  );
};
