import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#14708A",
    },
    error: {
      main: "#cd0037"
    }
  },
});
