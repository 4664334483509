import { cn } from "src/lib/utils";
import { Button } from "./button";

interface Props {
  title: string;
  description: string;
  price: string;
  href: string;
  className?: string;
}

export const DescriptionStickySection = ({ className, price, title, description,href }: Props) => {
  return (
    <div className={cn("bg-white min-h-[80px] sticky flex items-center justify-center md:justify-end px-[50px] xl:px-[130px]", className)}>
      <div className="flex items-center gap-2">
        <span className="text-black font-bold hidden md:block text-xl">
          {title} {description}
        </span>
        <Button className="justify-between text-sm md:text-base">
          <a href={href}>Acheter {title === "Découvrez le Pass Rail" ? "le pass" : "la carte"} {price}</a>
        </Button>
      </div>
    </div>
  );
};
