import { OffresMobileHeader } from './ui/offres-mobile-header'
import { OffresDesktopHeader } from './ui/offres-desktop-header'

export const OffresHeader = () => {
  return (
    <>
     <OffresMobileHeader />
     <OffresDesktopHeader />   
    </>
  )
}
