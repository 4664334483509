import { InactiveHeader } from "src/components/inactive-header";
import { OffresFooter } from "../../offres-footer"; 
import { AdulteDesktop } from "./main-desktop";
import { AdulteMobile } from "./main-mobile";
import { ChatbotSection } from "../../chatbot-section";
import { DescriptionBanner } from "src/components/responsive/description-banner";
import { PriceIds } from "src/lib/utils";
import { Helmet } from "react-helmet";

export const CarteAvantageAdulteView = () => {
  return (
    <>
      <Helmet>
        <title>Carte Avantage Adulte - SNCF Connect</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionBanner
      headerTitle="Carte Avantage Adulte"
      isStickyVisible
        mobileImage="https://imagedelivery.net/ogMRJiFkIuPcC6nnMct1Sg/c1ca532f-288e-462f-7740-9992c4e7d900/public"
        desktopImage="/avantage_adulte.jpg"
        badge="Promotion"
        badgeColor="#8de8fe"
        cardTitle="Carte Avantage Adulte"
        cardDescription="27 - 59 ans"
        floatingDescription="(27 - 59 ans)"
        price="2,45 €"
        buttonLabel="Commander la carte"
        href={`/app/offre/pricing/sncf?productId=${PriceIds["carte-avantage-adulte"]}`}
      />
      <AdulteDesktop />
      <AdulteMobile />
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
