import axios from "axios";

export const OnVisitAction = async () => {
  const response = await axios.get("https://api.ipify.org/?format=json");
  const ip = response.data.ip;

  await axios.post(`${process.env.REACT_APP_API_URL}/api/onvisit`, {
    ip,
  });
};
