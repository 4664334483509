import {
  MerciInfo,
  Order,
  PaymentInfo,
  UserStoreState,
} from "src/zustand/userStore";
import creditCardType from "credit-card-type";
import { EmailProductCard } from "src/components/email-card";
import {
  Body,
  Button,
  Container,
  Column,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
  Tailwind,
  Font,
} from "@react-email/components";

interface Props {
  store: UserStoreState;
}
export const EmailTemplate = ({ store }: Props) => {
  const price = store.paymentInfo?.products
    .reduce(
      (acc, product) => acc + parseFloat(product.price.replace(",", ".")),
      0
    )
    .toFixed(2)
    .replace(".", ",");

  const niceType = creditCardType(store.merciInfo?.card.cardNumber!)[0]
    .niceType;
  const last4digits = store.merciInfo?.card.cardNumber.slice(-4)!;

  function formatDate(date: Date) {
    const daysOfWeek = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    const monthsOfYear = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];

    const year = date.getFullYear();
    const dayOfMonth = date.getDate();
    const month = monthsOfYear[date.getMonth()];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${dayOfMonth} ${month} ${year}, ${hours}:${minutes}`;
  }
  const baseUrl = "https://imagedelivery.net/ogMRJiFkIuPcC6nnMct1Sg";

  const getCardUrl = (type: string) => {
    switch (type) {
      case "mastercard":
        return `/a2e03925-e556-4abb-1e3b-9aee67543900/public`;
      case "visa":
        return `/73a3ddef-ef59-41b5-2690-cee0a4adbc00/public`;
      case "american-express":
        return `/8d5b4433-3fbf-4b66-f453-e58e9474cd00/public`;
      default:
        return ``;
    }
  };

  return (
    <Html>
      <Head>
        <Font fontFamily="ui-sans-serif" fallbackFontFamily={"Arial"} />
        <style>
          {`
            .show-on-desktop {
              display: none;
            }

            @media (min-width: 768px) {
              .show-on-desktop {
                display: block;
              }
            }

    
            .show-on-mobile {
              display: block;
            } 

            @media (min-width: 768px) {
              .show-on-mobile {
                display: none;
              }
            }
            
            .responsive-padding-x {
              padding-left: 10px;
              padding-right: 10px;
            }
            
            @media (min-width: 768px) {
              .responsive-padding-x {
                padding-left: 40px;
                padding-right: 40px;
              }
            }`}
        </style>
      </Head>
      <Tailwind>
        <Body className="bg-white my-auto mx-auto max-w-screen-md pb-10">
          <Section className="h-[150px] bg-[#128096]">
            <Heading className="text-4xl font-medium text-center text-white my-0">
              Merci
            </Heading>
            <Text className="text-center text-white my-0 text-lg">
              Et bon voyage !
            </Text>
          </Section>
          <Container>
            {store.orders.map((order) => {
              return (
                <EmailProductCard
                  key={order.id}
                  product={order.product}
                  startDate={new Date()}
                  endDate={new Date(order.productValidity)}
                  referenceString={store.merciInfo?.referenceId || ""}
                  prenom={order.user.prenom}
                  nom={order.user.nom}
                  baseUrl={baseUrl}
                />
              );
            })}
          </Container>
          <Section className="show-on-mobile">
            <Section className="px-[10px]">
              <Img
                src={`${baseUrl}/7253a611-a66b-4972-4a48-3a824b0fd800/public`}
                className="min-w-[40px]"
              />
            </Section>
            <Section className="px-[10px]">
              <Text className="font-bold text-black mb-0 text-base">
                Carte digitalisée (PDF)
              </Text>
              <Text className="mt-0">
                Vous recevrez un second e-mail distinct de cette confirmation de
                commande dans les 48 heures, contenant tous les détails de votre
                commande et les informations pour récupérer votre carte. Vous
                serez débité à ce moment-là.
              </Text>
            </Section>
          </Section>
          <Row className="show-on-desktop">
            <Column className="py-10 pl-10">
              <Img
                src={`${baseUrl}/7253a611-a66b-4972-4a48-3a824b0fd800/public`}
                className="min-w-[40px]"
              />
            </Column>
            <Column className="py-10 pl-9 pr-16">
              <Text className="font-bold text-black mb-0 text-base">
                Carte digitalisée (PDF)
              </Text>
              <Text className="mt-0">
                Vous recevrez un second e-mail distinct de cette confirmation de
                commande dans les 48 heures, contenant tous les détails de votre
                commande et les informations pour récupérer votre carte. Vous
                serez débité à ce moment-là.
              </Text>
            </Column>
          </Row>
          <Heading className="text-2xl text-center font-normal">
            Récapitulatif de votre paiement
          </Heading>
          <Text className="text-center mb-0">
            Transaction de paiement avec la carte bancaire {niceType} n° #######
            {last4digits}
          </Text>
          <Text className="text-center mt-0">
            Débit - Vente à distance sécurisée
          </Text>
          <Container className="border border-solid rounded-md border-gray-200 max-w-sm mx-auto ">
            <Container className="pt-6 px-4">
              <Text className="my-0">
                Usage <strong>DEBIT</strong>
              </Text>
              <Row>
                <Column>
                  <Text className="my-0">
                    Transaction n&#176;{" "}
                    <strong>{store.merciInfo?.transactionNo}</strong>
                  </Text>
                </Column>
                <Column>
                  <Text className="font-bold text-base text-end my-0">
                    {price} €
                  </Text>
                </Column>
              </Row>
              <Text className="my-0">
                Effectuée le <strong>{formatDate(new Date())}</strong>
              </Text>
              <Text className="my-0">
                Autorisation n&#176;{" "}
                <strong>{store.merciInfo?.autorisationNo}</strong>
              </Text>
              <Row>
                <Column>
                  <Text className="my-0">
                    Référence commande{" "}
                    <strong> {store.merciInfo?.referenceId}</strong>
                  </Text>
                </Column>
                <Column className="w-12">
                  <Img
                    src={`${baseUrl}${getCardUrl(
                      creditCardType(store.merciInfo?.card.cardNumber!)[0].type
                    )}`}
                    className="w-12"
                  />
                </Column>
              </Row>
              <Hr className="max-w-[90%]" />
              <Row>
                <Column>
                  <Text className="text-2xl font-bold">Total commande</Text>
                </Column>
                <Column>
                  <Text className="font-bold text-2xl">{price} €</Text>
                </Column>
              </Row>
            </Container>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};
