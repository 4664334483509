import { InactiveHeader } from "src/components/inactive-header";
import { PassRailMainDesktop } from "./pass-rail-main-desktop";
import { OffresFooter } from "../../offres-footer";
import { PassRailMainMobile } from "./pass-rail-main-mobile";
import { ChatbotSection } from "../../chatbot-section";
import { DescriptionBanner } from "src/components/responsive/description-banner";
import { PriceIds } from "src/lib/utils";
import { Helmet } from "react-helmet";
export const PassRailDescriptionView = () => {

  return (
    <>
      <Helmet>
        <title>Découvrez le Pass Rail - SNCF Connect</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionBanner
        headerTitle="Découvrez le Pass Rail"
        desktopImage="/pass_rail.jpg"
        isStickyVisible
        mobileImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_360x214/public/media/2024-06/tetiere_catalogue.jpg?itok=vDpEQ5KU"
        cardTitle="Découvrez le Pass Rail"
        cardDescription="Entre 16 et 27 ans pour des voyages en juillet et août"
        floatingDescription="(Entre 16 et 27 ans pour des voyages en juillet et août)"
        price="2,45 €"
        badge="Nouveau"
        badgeColor="rgb(241, 200, 60)"
        buttonLabel="Acheter le pass"
        href={`/app/offre/pricing/sncf?productId=${PriceIds["pass-rail"]}`}
      />
      <PassRailMainDesktop />
      <PassRailMainMobile />
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
