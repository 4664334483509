import { Button } from "./button";
import { ChatbotSheet } from "./chatbot-sheet";

export const ChatbotSection = () => {
  return (
    <div
      aria-label="Besoin d’aide ?"
      role="region"
      style={{
        padding: "16px",
        boxSizing: "border-box",
        color: "rgb(12, 19, 31)",
        transition: "box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        borderRadius: "14px",
        overflow: "hidden",
        boxShadow: "none",
        border: "0px none rgb(12, 19, 31)",
        background:
          "rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box",
        display: "flex",
        justifyContent: "space-evenly",
        margin: "32px 0px",
      }}
    >
      <div
        style={{
          boxSizing: "border-box",
          display: "flex",
          flexFlow: "row wrap",
          width: "100%",
          WebkitBoxPack: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            WebkitBoxFlex: 1,
            flexGrow: 1,
            justifyContent: "stretch",
            flexBasis: "83.3333%",
            maxWidth: "83.3333%",
            boxSizing: "border-box",
            margin: "0px",
            WebkitBoxAlign: "center",
            alignItems: "center",
            display: "flex",
            WebkitBoxPack: "center",
            padding: "0px",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              WebkitBoxFlex: 1,
              flexGrow: 1,
              justifyContent: "stretch",
              boxSizing: "border-box",
              WebkitBoxAlign: "center",
              alignItems: "center",
              display: "flex",
              WebkitBoxPack: "center",
              padding: "0px",
            }}
          >
            <svg
              aria-hidden="true"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                height: "58px",
                width: "3.625rem",
                boxSizing: "border-box",
              }}
            >
              <path
                d="M40 78C60.9868 78 78 60.9868 78 40C78 19.0132 60.9868 2 40 2C19.0132 2 2 19.0132 2 40C2 60.9868 19.0132 78 40 78Z"
                fill="#127996"
                style={{ boxSizing: "border-box" }}
              ></path>
              <path
                d="M39.9996 72.8101C58.1201 72.8101 72.8097 58.1205 72.8097 40C72.8097 21.8794 58.1201 7.18985 39.9996 7.18985C21.879 7.18985 7.18945 21.8794 7.18945 40C7.18945 58.1205 21.879 72.8101 39.9996 72.8101Z"
                fill="white"
                style={{ boxSizing: "border-box" }}
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.3988 45.029C60.0345 45.029 62.1712 43.9608 62.1712 42.6428C62.1712 41.3249 60.0345 40.2567 57.3988 40.2567C54.763 40.2567 52.6265 41.3249 52.6265 42.6428C52.6265 43.9608 54.763 45.029 57.3988 45.029Z"
                fill="#FF97C7"
                style={{ boxSizing: "border-box" }}
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9916 45.029C26.6273 45.029 28.764 43.9608 28.764 42.6428C28.764 41.3249 26.6273 40.2567 23.9916 40.2567C21.3558 40.2567 19.2192 41.3249 19.2192 42.6428C19.2192 43.9608 21.3558 45.029 23.9916 45.029Z"
                fill="#FF97C7"
                style={{ boxSizing: "border-box" }}
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.3986 35.4843C57.3986 36.1922 57.1886 36.8842 56.7953 37.4728C56.402 38.0615 55.843 38.5202 55.189 38.7911C54.5349 39.062 53.8153 39.1329 53.1209 38.9948C52.4266 38.8567 51.7889 38.5158 51.2883 38.0152C50.7877 37.5146 50.4468 36.8768 50.3087 36.1825C50.1707 35.4882 50.2416 34.7685 50.5125 34.1145C50.7834 33.4604 51.2422 32.9014 51.8308 32.5082C52.4195 32.1149 53.1115 31.905 53.8194 31.905C54.2894 31.905 54.7549 31.9976 55.1891 32.1775C55.6234 32.3573 56.0179 32.621 56.3503 32.9534C56.6827 33.2857 56.9463 33.6803 57.1261 34.1146C57.306 34.5488 57.3986 35.0143 57.3986 35.4843Z"
                fill="#0C131F"
                style={{ boxSizing: "border-box" }}
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.1505 35.4843C31.1505 36.1922 30.9406 36.8842 30.5473 37.4728C30.154 38.0615 29.595 38.5202 28.9409 38.7911C28.2869 39.062 27.5672 39.1329 26.8729 38.9948C26.1786 38.8567 25.5408 38.5158 25.0402 38.0152C24.5397 37.5146 24.1988 36.8768 24.0607 36.1825C23.9226 35.4882 23.9935 34.7685 24.2644 34.1145C24.5354 33.4604 24.9942 32.9014 25.5828 32.5082C26.1714 32.1149 26.8634 31.905 27.5714 31.905C28.0414 31.905 28.5068 31.9976 28.9411 32.1775C29.3753 32.3573 29.7699 32.621 30.1023 32.9534C30.4346 33.2857 30.6982 33.6803 30.8781 34.1146C31.058 34.5488 31.1505 35.0143 31.1505 35.4843Z"
                fill="#0C131F"
                style={{ boxSizing: "border-box" }}
              ></path>
              <path
                d="M47.0512 44.5783C46.9316 44.4747 46.7758 44.4228 46.618 44.4341C46.4602 44.4454 46.3133 44.5189 46.2097 44.6385C45.1053 45.9131 43.0799 46.8188 40.6953 46.8188C38.3102 46.8188 36.2848 45.9131 35.1804 44.6385C35.1291 44.5793 35.0667 44.5308 34.9966 44.4957C34.9266 44.4606 34.8503 44.4397 34.7722 44.4341C34.694 44.4285 34.6155 44.4384 34.5412 44.4631C34.4669 44.4879 34.3982 44.527 34.3389 44.5783C34.2797 44.6296 34.2312 44.6921 34.1962 44.7621C34.1611 44.8322 34.1402 44.9084 34.1346 44.9866C34.129 45.0647 34.1388 45.1432 34.1636 45.2175C34.1883 45.2918 34.2275 45.3606 34.2788 45.4198C35.6538 47.0066 38.0375 48.0119 40.6953 48.0119C43.3527 48.0119 45.7365 47.0066 47.1114 45.4198C47.2149 45.3002 47.2668 45.1444 47.2555 44.9866C47.2443 44.8288 47.1708 44.6819 47.0512 44.5783Z"
                fill="#0C131F"
                style={{ boxSizing: "border-box" }}
              ></path>
            </svg>
            <p
              style={{
                paddingBottom: "0px",
                paddingLeft: "16px",
                paddingTop: "0px",
                fontSize: "16px",
                lineHeight: "22px",
                boxSizing: "border-box",
                margin: "0px",
                fontFamily: "Avenir",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Besoin d’aide ?
            </p>
            <p
              style={{
                paddingBottom: "0px",
                paddingLeft: "16px",
                paddingRight: "16px",
                fontSize: "16px",
                lineHeight: "22px",
                boxSizing: "border-box",
                margin: "0px",
                fontFamily: "Avenir",
                textAlign: "center",
              }}
            >
              Trouvons la réponse qui vous convient
            </p>
          </div>
          <ChatbotSheet>
            <Button variant="outline">Lancer la conversation</Button>
          </ChatbotSheet>
        </div>
      </div>
    </div>
  );
};
