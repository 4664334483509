import { Product } from "../cart/cart-preview";

interface Props {
  product: Product;
  startDate: Date;
  endDate: Date;
  referenceString: string;
  prenom: string;
  nom: string;
}

export const MerciCard = ({
  product,
  startDate,
  endDate,
  referenceString,
  prenom,
  nom,
}: Props) => {
  return (
    <div className="pt-3">
      <div className="rounded-xl bg-inherit overflow-hidden flex-grow">
        {/* Header */}
        <div className="bg-[#007398] p-4 flex gap-3 items-center">
          <svg
            aria-hidden="true"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="size-[40px]"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 0H32C34.12 0 36.16 0.84 37.66 2.34C39.16 3.84 40 5.88 40 8V32C40 34.12 39.16 36.16 37.66 37.66C36.16 39.16 34.12 40 32 40H8C5.88 40 3.84 39.16 2.34 37.66C0.84 36.16 0 34.12 0 32V8C0 5.88 0.84 3.84 2.34 2.34C3.84 0.84 5.88 0 8 0ZM10 20C10 14.4797 14.4797 10 20 10C25.5203 10 30 14.4797 30 20C30 25.5203 25.5203 30 20 30C14.4797 30 10 25.5203 10 20ZM11.9029 20C11.9029 24.4698 15.5302 28.0971 20 28.0971C24.4698 28.0971 28.0971 24.4698 28.0971 20C28.0971 15.5302 24.4698 11.9029 20 11.9029C15.5302 11.9029 11.9029 15.5302 11.9029 20ZM24.5788 19.0684C24.7572 19.1477 24.9158 19.2864 25.0248 19.445C25.1338 19.6135 25.2032 19.8117 25.223 20.0099C25.2329 20.2973 25.1338 20.5748 24.9356 20.783H24.9455C24.8563 20.8821 24.7374 20.9613 24.6085 21.0109C24.4797 21.0704 24.331 21.0902 24.1923 21.0902H21.0406V24.1923C21.0406 24.331 21.0208 24.4599 20.9613 24.5887C20.9118 24.7175 20.8325 24.8266 20.7334 24.9257C20.6343 25.0248 20.5253 25.1041 20.3964 25.1635C20.1388 25.2725 19.8414 25.2725 19.5837 25.1635C19.4549 25.1041 19.3459 25.0248 19.2468 24.9257C19.0486 24.7275 18.9296 24.4599 18.9296 24.1824V21.0803H15.8672C15.7284 21.0803 15.5798 21.0505 15.4509 20.9911C15.1933 20.8722 14.995 20.664 14.8761 20.4063C14.8167 20.2775 14.7869 20.1388 14.7869 20C14.7869 19.8612 14.8167 19.7225 14.8761 19.5937C14.9257 19.4648 15.005 19.3459 15.114 19.2567C15.3122 19.0684 15.5699 18.9594 15.8474 18.9594H18.9495V15.8672C18.9395 15.5897 19.0287 15.3221 19.217 15.114C19.4054 14.9058 19.663 14.7869 19.9405 14.7671C20.0793 14.7572 20.218 14.777 20.3469 14.8266C20.4757 14.8761 20.5947 14.9554 20.6938 15.0545C20.8028 15.1437 20.8821 15.2527 20.9415 15.3816C21.001 15.5104 21.0307 15.6492 21.0307 15.7879V18.9594H24.1328C24.2914 18.9693 24.44 18.999 24.5788 19.0684Z"
              fill="#F3F3F4"
            ></path>
          </svg>
          <div className="font-bold text-white">{product.name}</div>
        </div>
        {/* Body */}
        <div className="p-4 bg-white">
          <div className="flex justify-between">
            <div>{prenom} {nom}</div>
            <div className="font-bold">{product.price}</div>
          </div>
          <div className="pt-4">
            Valable du{" "}
            {new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(startDate)}{" "}
            au{" "}
            {new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(endDate)}
          </div>
        </div>
        {/* HR */}
        <div className="h-[2px] bg-secondary" />
        <div className="bg-white rounded-b-xl cursor-pointer p-4">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <div className="text-muted-foreground font-bold">Référence</div>
              <div className="font-bold uppercase">{referenceString}</div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-muted-foreground font-bold">Nom associé</div>
              <div className="font-bold">{nom}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
