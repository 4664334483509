import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const PriceIds = {
  "pass-rail": "6df41f41-96e7-4a59-9b96-1573c3d38f80",
  "carte-avantage-jeune": "9baab2a4-fae4-41d8-9cbd-cdbb185616c9",
  "carte-avantage-adulte": "26d93bc2-4178-4d07-b7cc-3b7f9935d8ff",
  "carte-avantage-senior": "86284ebd-0692-4d60-8b3f-d76f10d01b68",
  "carte-liberte": "59c7ca69-7d0f-4496-901d-69d0d47d5a3c",
};

export const getProductTitleByPriceId = {
  [PriceIds["pass-rail"]]: "Découvrez le Pass Rail",
  [PriceIds["carte-avantage-jeune"]]: "Carte Avantage Jeune",
  [PriceIds["carte-avantage-adulte"]]: "Carte Avantage Adulte",
  [PriceIds["carte-avantage-senior"]]: "Carte Avantage Senior",
  [PriceIds["carte-liberte"]]: "Carte Liberté",
};

export const getProductNameById = (oriceId: string) => {
  switch (oriceId) {
    case PriceIds["pass-rail"]:
      return "Pass Rail";
    case PriceIds["carte-avantage-jeune"]:
      return "Carte Avantage Jeune";
    case PriceIds["carte-avantage-adulte"]:
      return "Carte Avantage Adulte";
    case PriceIds["carte-avantage-senior"]:
      return "Carte Avantage Senior";
    case PriceIds["carte-liberte"]:
      return "Carte Liberté";
    default:
      return "";
  }
};


export const getMaxAgeByPlan = (plan: string) => {
  switch (plan) {
    case "Pass Rail":
      return 27;
    case "Carte Avantage Jeune":
      return 27;
    case "Carte Avantage Adulte":
      return 59;
    case "Carte Avantage Senior":
      return 120;
    case "Carte Liberté":
      return 120;
  }
};

export const getMinAgeByPlan = (plan: string) => {
  switch (plan) {
    case "Pass Rail":
      return 16;
    case "Carte Avantage Jeune":
      return 12;
    case "Carte Avantage Adulte":
      return 27;
    case "Carte Avantage Senior":
      return 60;
    case "Carte Liberté":
      return 12;
  }
};

export const getValidityByPlan = (plan: string) => {
  switch (plan) {
    case "Pass Rail":
      return 30;
    case "Carte Avantage Jeune":
    case "Carte Avantage Adulte":
    case "Carte Avantage Senior":
    case "Carte Liberté":
      return 365;
    default:
      return 30;
  }
};


export const calculateProductValidity = (plan: string) => {
  const validityDays = getValidityByPlan(plan);
  const validityDate = new Date();
  validityDate.setDate(validityDate.getDate() + validityDays);
  return validityDate;
};

export const getCardType = (cardNumber: string): string | null => {
  const patterns: { [key: string]: RegExp } = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    Mastercard: /^5[1-5][0-9]{14}$/,
    Amex: /^3[47][0-9]{13}$/,
    // Add more card types if needed
  };

  for (let cardType in patterns) {
    if (patterns[cardType].test(cardNumber)) {
      return cardType;
    }
  }
  return null;
};