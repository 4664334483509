export const MerciImage = () => {
  return (
    <img
      src="/merci-checkmark.png"
      alt=""
      className="object-cover object-center select-none min-h-[250px]"
      draggable={false}
      onContextMenu={(e) => e.preventDefault()}
    />
  );
};
