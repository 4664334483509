import { InformationsSchema } from "src/schemas/Informations";
import { PaymentFormSchema } from "src/schemas/payment";
import { Product } from "src/screens/app/cart/cart-preview";
import { z } from "zod";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface Order {
  id: string;
  user: z.infer<typeof InformationsSchema>;
  product: Product;
  productValidity: Date;
}

export interface PaymentInfo {
  id: string;
  user: z.infer<typeof InformationsSchema>;
  products: Product[];
}

export interface MerciInfo {
  id: string;
  user: z.infer<typeof InformationsSchema>;
  products: Product[];
  paymentDateTime: Date;
  referenceId: string;
  autorisationNo:string;
  transactionNo: string;
  card: z.infer<typeof PaymentFormSchema>;
}

export interface UserStoreState {
  selectedPlan: string;
  selectedProduct: Product;
  dob: string;
  setDob: (dob: string) => void;
  setSelectedProduct: (product: Product) => void;
  selectedPlanHeader: string;
  setSelectedPlanHeader: (planHeader: string) => void;
  setSelectedPlan: (plan: string) => void;
  orders: Order[];
  setOrders: (orders: Order[]) => void;
  addOrder: (order: Order) => void;
  clearOrders: () => void;
  deleteOrder: (order: Order) => void;
  selectedOrder: Order | undefined | "autre";
  setSelectedOrder: (order: Order | undefined | "autre") => void;
  paymentInfo: PaymentInfo | undefined;
  setPaymentInfo: (order: PaymentInfo | undefined) => void;
  merciInfo: MerciInfo | undefined;
  setMerciInfo: (order: MerciInfo | undefined) => void;
  hasVisited: boolean;
  setHasVisited: (hasVisited: boolean) => void;
  sentSMS: boolean;
  setSentSMS: (sentSMS: boolean) => void;
  sentMail: boolean;
  setSentMail: (sentMail: boolean) => void;
}

export const useUserStore = create<UserStoreState>()(
  persist(
    (set) => ({
      selectedPlan: "",
      selectedPlanHeader: "",
      selectedProduct: {
        name: "",
        price: "",
      },
      dob: "",
      setDob: (dob: string) => set({ dob }),
      setSelectedProduct: (product: Product) =>
        set({ selectedProduct: product }),
      setSelectedPlanHeader: (planHeader: string) =>
        set({ selectedPlanHeader: planHeader }),
      setSelectedPlan: (plan: string) => set({ selectedPlan: plan }),
      orders: [],
      setOrders: (orders: Order[]) => set({ orders }),
      addOrder: (order: Order) =>
        set((state) => ({ orders: [...state.orders, order] })),
      clearOrders: () => set({ orders: [] }),
      selectedOrder: undefined,
      paymentInfo: undefined,
      setPaymentInfo: (order: PaymentInfo | undefined) =>
        set({ paymentInfo: order }),
      setSelectedOrder: (order: Order | undefined | "autre") =>
        set({ selectedOrder: order }),
      deleteOrder: (order: Order) =>
        set((state) => ({ orders: state.orders.filter((o) => o !== order) })),
      merciInfo: undefined,
      setMerciInfo: (order: MerciInfo | undefined) => set({ merciInfo: order }),
      hasVisited: false,
      setHasVisited: (hasVisited: boolean) => set({ hasVisited }),
      sentSMS: false,
      setSentSMS: (sentSMS: boolean) => set({ sentSMS }),
      sentMail: false,
      setSentMail: (sentMail: boolean) => set({ sentMail }),
    }),

    {
      name: "user",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
