import {
  Body,
  Column,
  Container,
  Img,
  Row,
  Section,
  Text,
  Tailwind,
} from "@react-email/components";
import { Product } from "src/screens/app/cart/cart-preview";

interface Props {
  product: Product;
  startDate: Date;
  endDate: Date;
  referenceString: string;
  prenom: string;
  nom: string;
  baseUrl: string;
}
export const EmailProductCard = ({
  product,
  startDate,
  endDate,
  referenceString,
  prenom,
  nom,
  baseUrl,
}: Props) => {
  return (
    <Tailwind>
      <Section className="responsive-padding-x mt-10 max-w-[37.5rem]">
        <Row>
          {/* Header */}
          <Row className="bg-[#007398] p-3 rounded-t-xl">
            <Column className="max-w-[20px]">
              <Img
                src={`${baseUrl}/8e0765c5-20b4-43ec-94a2-17c3ff733700/public`}
                className="text-white fill-white size-[40px]"
              />
            </Column>
            <Column className="text-start">
              <Text className="font-bold text-base text-white text-start mr-auto p-0">
                {product.name}
              </Text>
            </Column>
            <Column>
              <Text className="ml-auto text-white text-end px-5 font-bold text-base p-0">
                {product.price}
              </Text>
            </Column>
          </Row>
          {/* Body */}
          <Container className="rounded-b-lg border-t-0 border-2 border-gray-200 mb-2">
            <Row className="bg-white cursor-pointer show-on-desktop">
              <Column className="p-4 w-full">
                <Row>
                  <Column>
                    <Text className="text-gray-500 my-0 pr-1">Valable du</Text>
                  </Column>
                  <Column>
                    <Text className="text-black font-bold my-0">
                      {new Intl.DateTimeFormat("fr-FR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(startDate)}
                    </Text>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Text className="text-gray-500 my-0 pr-1">Valable au </Text>
                  </Column>
                  <Column>
                    <Text className="text-black font-bold my-0">
                      {new Intl.DateTimeFormat("fr-FR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(endDate)}
                    </Text>
                  </Column>
                </Row>
                <Text className="bg-[#F7F6F3] rounded-full px-4 py-2 mt-2 font-semibold w-fit max-w-[150px] truncate">
                  {prenom} {nom}
                </Text>
              </Column>
              <Column
                className="overflow-hidden align-top py-4 ml-auto"
                align="right"
              >
                <Section className="border-l border-gray-200 border-solid border-0">
                  <Row className="pl-4">
                    <Column className="px-1 align-top">
                      <Row>
                        <Text className="text-gray-500 my-0">Référence</Text>
                      </Row>
                      <Row>
                        <Text className="font-bold text-lg my-0">
                          {referenceString}
                        </Text>
                      </Row>
                    </Column>
                    <Column className="px-1">
                      <Row>
                        <Text className="text-gray-500 my-0">Nom</Text>
                      </Row>
                      <Row>
                        <Text className="font-bold text-lg truncate max-w-[150px] my-0">
                          {nom}
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                </Section>
              </Column>
            </Row>
            <Column className="bg-white cursor-pointer show-on-mobile">
              <Row className="gap-2 flex-1 p-4">
                <Row className="px-2">
                  <Column>
                    <Text className="text-gray-500 my-0">Valable du</Text>
                  </Column>
                  <Column>
                    <Text className="text-black font-bold my-0 text-end">
                      {new Intl.DateTimeFormat("fr-FR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(startDate)}
                    </Text>
                  </Column>
                </Row>
                <Row className="px-2">
                  <Column>
                    <Text className="text-gray-500 my-0">Valable au </Text>
                  </Column>
                  <Column>
                    <Text className="text-black font-bold my-0 text-end">
                      {new Intl.DateTimeFormat("fr-FR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(endDate)}
                    </Text>
                  </Column>
                </Row>
              </Row>
              <Row className="overflow-hidden align-top show-on-mobile">
                <Section className="border-l border-gray-200 border-solid border-0 show-on-mobile">
                  <Row className="px-2">
                    <Column className="align-top">
                      <Row>
                        <Text className="text-gray-500 my-0 text-start">
                          Référence
                        </Text>
                      </Row>
                      <Row>
                        <Text className="font-bold text-lg my-0 text-start">
                          {referenceString}
                        </Text>
                      </Row>
                    </Column>
                    <Column className="pl-3">
                      <Row>
                        <Text className="text-gray-500 my-0">Nom</Text>
                      </Row>
                      <Row>
                        <Text className="font-bold text-lg truncate max-w-[150px] my-0">
                          {nom}
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Text className="bg-[#F7F6F3] rounded-full px-4 py-2 mt-2 font-semibold w-fit max-w-[150px] truncate show-on-mobile">
                    {prenom} {nom}
                  </Text>
                </Section>
              </Row>
            </Column>
          </Container>
        </Row>
      </Section>
    </Tailwind>
  );
};
