import { Offer } from "src/screens/app/catalogue/offres-main";
import { OffresCardMobile } from "./offres-card-mobile";

interface Props {
  offers: Offer[];
}
export const OffresMainMobile = ({ offers }: Props) => {
  return (
    <div
      style={{
        boxSizing: "border-box",
        backgroundColor: "rgb(243, 243, 248)",
        minHeight: "100%",
      }}
      className="xl:hidden"
    >
      <div
        style={{
          boxSizing: "border-box",
          padding: "0px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          paddingLeft: "0px",
          paddingRight: "0px",
          height: "auto",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            flexFlow: "row wrap",
            width: "100%",
            WebkitBoxPack: "center",
            justifyContent: "center",
            height: "546px",
          }}
        >
          <div
            style={{
              boxSizing: "border-box",
              margin: "0px",
              flexDirection: "row",
              flexBasis: "100%",
              WebkitBoxFlex: 0,
              flexGrow: 0,
              maxWidth: "100%",
              padding: "0px 16px",
            }}
          >
            <div
              data-test="breadcrumb"
              style={{
                boxSizing: "border-box",
                margin: "24px 0px 0px",
                fontFamily: "Avenir",
                fontSize: "16px",
                lineHeight: "22px",
                fontWeight: 700,
                color: "rgb(94, 104, 120)",
                padding: "0px",
              }}
            >
              <ol
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxAlign: "center",
                  alignItems: "center",
                  padding: "0px",
                  margin: "0px",
                  listStyle: "outside none none",
                }}
              >
                <li style={{ boxSizing: "border-box" }}>
                  <a
                    href="/app"
                    style={{
                      boxSizing: "border-box",
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      margin: "0px",
                      fontFamily: "Avenir",
                      fontSize: "13px",
                      lineHeight: "18px",
                      fontWeight: 700,
                      color: "rgb(20, 112, 138)",
                      textDecoration: "none solid rgb(20, 112, 138)",
                      cursor: "pointer",
                      position: "relative",
                      WebkitBoxAlign: "center",
                      alignItems: "center",
                      display: "inline-flex",
                      padding: "0px",
                    }}
                  >
                    Accueil
                  </a>
                </li>
                <li
                  aria-hidden="true"
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    userSelect: "none",
                    marginLeft: "8px",
                    marginRight: "8px",
                  }}
                >
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      boxSizing: "border-box",
                      height: "13px",
                      margin: "2px -8px 0px",
                      width: "1.25rem",
                    }}
                  >
                    <path
                      d="M8.79362 6.6659C8.36568 7.06175 8.36568 7.73825 8.79362 8.1341L12.973 12L8.79362 15.8659C8.36568 16.2617 8.36568 16.9383 8.79362 17.3341L8.83447 17.3719C9.21775 17.7264 9.80928 17.7264 10.1926 17.3719L15.2064 12.7341C15.6343 12.3383 15.6343 11.6617 15.2064 11.2659L10.1926 6.62811C9.80928 6.27358 9.21775 6.27358 8.83447 6.62811L8.79362 6.6659Z"
                      style={{ boxSizing: "border-box" }}
                    ></path>
                  </svg>
                </li>
                <li style={{ boxSizing: "border-box" }}>
                  <p
                    aria-current="page"
                    style={{
                      boxSizing: "border-box",
                      margin: "2px 0px 0px",
                      fontFamily: "Avenir",
                      fontSize: "13px",
                      lineHeight: "18px",
                      fontWeight: 700,
                      color: "rgb(12, 19, 31)",
                    }}
                  >
                    Offres
                  </p>
                </li>
              </ol>
            </div>
            <h1
              style={{
                boxSizing: "border-box",
                fontSize: "30px",
                margin: "40px 0px 32px",
                fontFamily: "Achemine-Bold, Arial, Helvetica, sans-serif",
                lineHeight: "38px",
                fontWeight: 700,
              }}
            >
              Offres et services
            </h1>
            <div style={{ boxSizing: "border-box", display: "flex" }}>
              <h2
                style={{
                  boxSizing: "border-box",
                  margin: "0px",
                  fontFamily: "Avenir",
                  fontSize: "20px",
                  lineHeight: "26px",
                  fontWeight: 800,
                  flex: "1 1 0%",
                }}
              >
                Cartes et abonnements SNCF
              </h2>
              <div
                style={{
                  boxSizing: "border-box",
                  flexShrink: 0,
                  marginRight: "-16px",
                  marginTop: "-8px",
                }}
              >
                <a
                  href="/app/offre/cartes-et-abonnements"
                  style={{
                    boxSizing: "border-box",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    margin: "0px",
                    fontFamily: "Avenir",
                    fontSize: "13px",
                    lineHeight: "18px",
                    fontWeight: 500,
                    color: "rgb(20, 112, 138)",
                    textDecoration: "none solid rgb(20, 112, 138)",
                    cursor: "pointer",
                    position: "relative",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    display: "inline-flex",
                    padding: "15px",
                    boxShadow: "none",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    textTransform: "none",
                  }}
                >
                  Voir tout
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      boxSizing: "border-box",
                      height: "16px",
                      width: "1rem",
                      alignSelf: "normal",
                      fill: "rgb(20, 112, 138)",
                      flexShrink: 0,
                      marginLeft: "6px",
                    }}
                  >
                    <path
                      d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 10.4241 23.6896 8.8637 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40041 18.0481 1.5165 16.5922 0.913445C15.1363 0.310389 13.5759 0 12 0ZM16.0464 12.5177L11.4398 16.9615C11.3024 17.094 11.118 17.1666 10.9271 17.1632C10.7362 17.1598 10.5545 17.0807 10.422 16.9433C10.2894 16.806 10.2169 16.6215 10.2203 16.4307C10.2237 16.2398 10.3028 16.0581 10.4402 15.9256L14.5095 11.9998L10.4402 8.07458C10.3028 7.94203 10.2237 7.76033 10.2203 7.56946C10.2169 7.37859 10.2894 7.19419 10.422 7.05681C10.5545 6.91944 10.7362 6.84035 10.9271 6.83694C11.118 6.83354 11.3024 6.90609 11.4398 7.03865L16.0464 11.4818C16.1161 11.5489 16.1715 11.6293 16.2093 11.7183C16.2472 11.8073 16.2667 11.9031 16.2667 11.9998C16.2667 12.0965 16.2472 12.1922 16.2093 12.2812C16.1715 12.3702 16.1161 12.4507 16.0464 12.5177Z"
                      style={{ boxSizing: "border-box" }}
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div style={{ boxSizing: "border-box", margin: "0px 0px 24px" }}>
              <div
                style={{
                  position: "relative",
                  transform: "matrix(1, 0, 0, 1, -16, 0)",
                  width: "calc(100% + 2rem)",
                  boxSizing: "border-box",
                  padding: "16px 0px",
                }}
              >
                <ul
                  style={{
                    display: "flex",
                    overflow: "auto",
                    padding: "0px 16px",
                    scrollSnapType: "x mandatory",
                    boxSizing: "border-box",
                    listStyle: "outside none none",
                    margin: "0px",
                  }}
                >
                  {offers.map((offer) => (
                    <OffresCardMobile
                      key={offer.title}
                      href={offer.href}
                      img={offer.img}
                      title={offer.title}
                      description={offer.description}
                      oldPrice={offer.oldPrice}
                      newPrice={offer.newPrice}
                      badge={offer.badge}
                      planValidity={offer.planValidity}
                      badgeColor={offer.badgeColor}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
