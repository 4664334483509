import { OffresMainDesktop } from "src/components/ui/offres-main-desktop";
import { OffresMainMobile } from "src/components/ui/offres-main-mobile";
import { desktopOffers, mobileOffers } from "src/lib/offres";

export interface Offer {
  href: string;
  img: string;
  title: string;
  description: string;
  oldPrice: React.ReactNode;
  newPrice: string;
  planValidity: string;
  badge?: string;
  badgeColor?: string;
}

export const OffresMain = () => {

  return (
    <>
      <OffresMainDesktop offers={desktopOffers as Offer[]} />
      <OffresMainMobile offers={mobileOffers as Offer[]} />
    </>
  );
};
