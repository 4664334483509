import { TextField, ThemeProvider } from "@mui/material";
import React from "react";
import { theme } from "src/MuiSetup";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  label: string;
  value?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
}

export const MuiInput = ({
  label,
  value,
  onChange,
  className,
  error,
}: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        id={label}
        value={value}
        onChange={onChange}
        label={
          <span
            style={{ font: "Avenir", fontWeight: 500 }}
            className="font-medium"
          >
            {label}
          </span>
        }
        InputProps={{ style: { borderRadius: "10px" } }}
        color={error ? "error" : "primary"}
        className={className}
        style={{
          font: "Avenir",
        }}
      />
    </ThemeProvider>
  );
};
