import { motion } from "framer-motion";
import { DescriptionStickySection } from "./description-sticky-section";

interface Props {
  title: string;
  description?: string;
  price?: string;
  href?: string;
  isInView?: boolean;
  isStickyVisible?: boolean;
}

export const DescriptionHeader = ({
  title,
  isInView,
  href,
  price,
  description,
  isStickyVisible = false,
}: Props) => {
  return (
    <>
      <header className="flex flex-col w-full flex-shrink-0 sticky z-[1100] top-0 left-auto right-0 bg-[#0c131f] text-[#f3f3f4] h-[3.5rem] xl:h-[4.5rem] justify-center py-4">
        <div className="max-w-[1440px] w-full mx-auto px-4">
          <div className="flex relative items-center p-0 h-6 min-h-0">
            <h1 className="m-0 font-bold text-lg flex-1 xl:text-2xl">
              {title}
            </h1>
          </div>
        </div>
      </header>
      {!isInView && isStickyVisible && (
        <motion.div
          className="sticky top-[3.5rem] xl:top-[4.5rem] z-[1099]"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <DescriptionStickySection
            title={title}
            href={href|| ""}
            description={description ?? ""}
            price={price || ""}
          />
        </motion.div>
      )}
    </>
  );
};
