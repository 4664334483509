interface Props {
  className?: string;
}

export const CardSvg = ({ ...props }: Props) => {
  return (
    <svg
      aria-hidden="true"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.3259 35.1101H21.6173C20.9895 35.1101 20.4664 35.5905 20.4664 36.167C20.4664 36.7435 20.9895 37.2239 21.6173 37.2239H26.3259C26.9537 37.2239 27.4768 36.7435 27.4768 36.167C27.4768 35.5905 26.9537 35.1101 26.3259 35.1101ZM42.6487 35.1101H37.9402C37.3124 35.1101 36.7892 35.5905 36.7892 36.167C36.7892 36.7435 37.3124 37.2239 37.9402 37.2239H42.6487C43.2765 37.2239 43.7997 36.7435 43.7997 36.167C43.7997 35.5905 43.2765 35.1101 42.6487 35.1101ZM47.148 11.667H8.85201C6.55006 11.667 4.66666 13.3964 4.66666 15.5101V40.4905C4.66666 42.6043 6.55006 44.3337 8.85201 44.3337H47.148C49.4499 44.3337 51.3333 42.6043 51.3333 40.4905V15.5101C51.3333 13.3964 49.4499 11.667 47.148 11.667ZM49.0314 40.4905C49.0314 41.4513 48.1943 42.2199 47.148 42.2199H8.85201C7.80567 42.2199 6.9686 41.4513 6.9686 40.4905V15.5101C6.9686 14.5493 7.80567 13.7807 8.85201 13.7807H47.148C48.1943 13.7807 49.0314 14.5493 49.0314 15.5101V40.4905ZM18.1644 35.1101H13.4559C12.8281 35.1101 12.3049 35.5905 12.3049 36.167C12.3049 36.7435 12.8281 37.2239 13.4559 37.2239H18.1644C18.7922 37.2239 19.3154 36.7435 19.3154 36.167C19.3154 35.5905 18.7922 35.1101 18.1644 35.1101ZM22.8729 18.969H16.8042C14.293 18.969 12.2003 20.8905 12.2003 23.1964V25.2141C12.2003 27.5199 14.293 29.4415 16.8042 29.4415H22.8729C25.3841 29.4415 27.4768 27.5199 27.4768 25.2141V23.1964C27.4768 20.7944 25.3841 18.969 22.8729 18.969ZM25.0702 25.2141C25.0702 26.367 24.0239 27.3278 22.7683 27.3278H16.6995C15.4439 27.3278 14.3976 26.367 14.3976 25.2141V23.1964C14.3976 22.0435 15.4439 21.0827 16.6995 21.0827H22.7683C24.0239 21.0827 25.0702 22.0435 25.0702 23.1964V25.2141ZM34.4873 35.1101H29.7788C29.151 35.1101 28.6278 35.5905 28.6278 36.167C28.6278 36.7435 29.151 37.2239 29.7788 37.2239H34.4873C35.1151 37.2239 35.6383 36.7435 35.6383 36.167C35.6383 35.5905 35.1151 35.1101 34.4873 35.1101Z"
        fill="#0C131F"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.9167 17.5C51.9167 22.3325 47.9991 26.25 43.1667 26.25C38.3342 26.25 34.4167 22.3325 34.4167 17.5C34.4167 12.6675 38.3342 8.75 43.1667 8.75C47.9991 8.75 51.9167 12.6675 51.9167 17.5ZM47.0475 14.2398L41.8368 19.5592L39.2858 16.955C38.9726 16.6353 38.4648 16.6353 38.1516 16.955C37.8384 17.2748 37.8384 17.7932 38.1516 18.1129L41.8368 21.875L48.1817 15.3977C48.495 15.078 48.495 14.5596 48.1817 14.2398C47.8685 13.9201 47.3607 13.9201 47.0475 14.2398Z"
        fill="#007C33"
      ></path>
    </svg>
  );
};
