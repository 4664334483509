import { Navigate, Route, RouteProps, Routes } from "react-router-dom";
import { OffresView } from "./screens/app/catalogue";
import { PassRailDescriptionView } from "./screens/app/catalogue/description/PassRail";
import { CarteJeuneDescriptionView } from "./screens/app/catalogue/description/CarteAdvantageJeune";
import { CarteAvantageAdulteView } from "./screens/app/catalogue/description/CarteAdvantageAdulte";
import { CarteAvantageSeniorView } from "./screens/app/catalogue/description/CarteAdvantageSenior";
import { CarteLiberteView } from "./screens/app/catalogue/description/CarteLiberte";
import { Informations } from "./screens/app/catalogue/pricing/sncf-step3";
import { CartView } from "./screens/app/cart";
import { PaiementView } from "./screens/app/paiement";
import { Merci } from "./screens/app/merci";
import { BasePricingView } from "./screens/app/catalogue/pricing/etape-1";
import { CartesEtAbonnementsScreen } from "./screens/app/catalogue/cartes-et-abonnements";

export const RouteManager = () => {
  const routesConfig: RouteProps[] = [
    { path: "/app/offre", element: <OffresView /> },
    { path: "/app/offre/cartes-et-abonnements", element: <CartesEtAbonnementsScreen /> },
    {
      path: "/app/offre/description/pass-rail",
      element: <PassRailDescriptionView />,
    },
    {
      path: "/app/offre/description/carte-avantage-jeune",
      element: <CarteJeuneDescriptionView />,
    },
    {
      path: "/app/offre/description/carte-avantage-adulte",
      element: <CarteAvantageAdulteView />,
    },
    {
      path: "/app/offre/description/carte-avantage-senior",
      element: <CarteAvantageSeniorView />,
    },
    {
      path: "/app/offre/description/carte-liberte",
      element: <CarteLiberteView />,
    },
    {
      path: "/app/offre/pricing/sncf-step3",
      element: <Informations />,
    },
    {
      path: "/app/cart",
      element: <CartView />,
    },
    {
      path: "/app/paiement",
      element: <PaiementView />,
    },
    {
      path: "/app/merci",
      element: <Merci />,
    },
  ];

  const pricingRoutes: RouteProps[] = [
    {
      path: `/app/offre/pricing/sncf`,
      element: <BasePricingView />,
    },
  ];

  const routes = () => (
    <>
      {routesConfig.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
      {pricingRoutes.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/app/offre" replace />} />
    </>
  );

  return <Routes>{routes()}</Routes>;
};
