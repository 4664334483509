import { ChatbotSection } from "src/components/ui/desktop-chat-bot-section";

export const OffresDesktopChatbotWrap = () => {
  return (
    <div
      style={{
        WebkitBoxAlign: "stretch",
        alignItems: "stretch",
        backgroundImage:
          "linear-gradient(90deg, rgb(243, 243, 248) 50%, rgb(243, 243, 248) 50%)",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "rgb(243, 243, 248)",
        minHeight: "100%",
      }}
      className="hidden xl:flex"
    >
      <div
        style={{
          maxWidth: "1440px",
          paddingLeft: "0px",
          paddingRight: "0px",
          boxSizing: "border-box",
          padding: "0px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          height: "auto",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            flexFlow: "row wrap",
            width: "100%",
            WebkitBoxPack: "center",
            justifyContent: "center",
            height: "154px",
          }}
        >
          <div
            style={{
              paddingLeft: "80px",
              paddingRight: "80px",
              backgroundColor: "rgb(243, 243, 248)",
              flexBasis: "100%",
              WebkitBoxFlex: 0,
              flexGrow: 0,
              maxWidth: "100%",
              boxSizing: "border-box",
              margin: "0px",
              flexDirection: "row",
            }}
          >
            <ChatbotSection />
          </div>
        </div>
      </div>
    </div>
  );
};
