import { z } from "zod";

export const BasePricingSchema = z
  .object({
    dateOfBirth: z.string(),
    minAge: z.number(),
    maxAge: z.number(),
  })
  .superRefine(({ dateOfBirth, minAge, maxAge }, ctx) => {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

    if (!dateRegex.test(dateOfBirth)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["dateOfBirth"],
        message: "Date de naissance invalide. Format attendu : JJ/MM/AAAA.",
      });
      return;
    }

    const dateParts = dateOfBirth.split("/");
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    // Check for valid month
    if (month < 1 || month > 12) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["dateOfBirth"],
        message: "Mois invalide. Veuillez saisir un mois entre 01 et 12.",
      });
      return;
    }

    // Check for valid day
    const daysInMonth = new Date(year, month, 0).getDate(); // Last day of the given month
    if (day < 1 || day > daysInMonth) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Jour invalide. Veuillez saisir un jour entre 01 et ${daysInMonth} pour le mois donné.`,
      });
      return;
    }

    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--; // Decrement the age if the birthday hasn't occurred yet this year
    }

    if (age > maxAge) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["dateOfBirth"],
        message: `L'âge doit être inférieur à ${maxAge} ans. Veuillez saisir une date de naissance valide.`,
      });
    } else if (age < minAge) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["dateOfBirth"],
        message: `L'âge doit être au moins de ${minAge} ans. Veuillez saisir une date de naissance valide.`,
      });
    }
  });
