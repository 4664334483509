import { OffresHeader } from "src/components/offres-header";
import { OffresMain } from "./offres-main";
import { OffresFooter } from "./offres-footer";
import { ChatbotSection } from "./chatbot-section";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useUserStore } from "src/zustand/userStore";
import { OnVisitAction } from "src/actions/onVisit";
export const OffresView = () => {
  
  const store = useUserStore();

  useEffect(() => {
    if (!store.hasVisited) {
      OnVisitAction();
      store.setHasVisited(true);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Achat cartes de réductions & abonnements SNCF, billets TER, tickets
          métro & bus - SNCF Connect
        </title>
      </Helmet>
      <OffresHeader />
      <OffresMain />
      <ChatbotSection />
      <OffresFooter />
    </div>
  );
};
