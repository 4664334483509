import { Offer } from "src/screens/app/catalogue/offres-main";

export const baseOffers: Partial<Offer>[] = [
  {
    href: "/app/offre/description/pass-rail",
    title: "Découvrez le Pass Rail",
    description: "Entre 16 et 27 ans pour des voyages en juillet et août",
    badge: "Nouveau",
    badgeColor: "rgb(241, 200, 60)",
    oldPrice: (
      <span className="text-lg text-black">
        2,45 €{" "}
        <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
      </span>
    ),
    newPrice: "2,45 €",
    planValidity: "Mensuel",
  },
  {
    href: "/app/offre/description/carte-avantage-jeune",
    title: "Carte Avantage Jeune",
    description: "12 - 27 ans",
    badge: "Promotion",
    badgeColor: "#8de8fe",
    oldPrice: (
      <span className="text-lg text-black">
        2,45 €{" "}
        <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
      </span>
    ),
    newPrice: "2,45 €",
    planValidity: "Annuel",
  },
  {
    href: "/app/offre/description/carte-avantage-adulte",
    title: "Carte Avantage Adulte",
    description: "27 - 59 ans",
    badge: "Promotion",
    badgeColor: "#8de8fe",
    oldPrice: (
      <span className="text-lg text-black">
        2,45 €{" "}
        <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
      </span>
    ),
    newPrice: "2,45 €",
    planValidity: "Annuel",
  },
  {
    href: "/app/offre/description/carte-avantage-senior",
    title: "Carte Avantage Senior",
    description: "+ 60 ans",
    badge: "Promotion",
    badgeColor: "#8de8fe",
    oldPrice: (
      <span className="text-lg text-black">
        2,45 €{" "}
        <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
      </span>
    ),
    newPrice: "2,45 €",
    planValidity: "Annuel",
  },
  {
    href: "/app/offre/description/carte-liberte",
    title: "Carte Liberté",
    badge: "Promotion",
    badgeColor: "#8de8fe",
    description: "La Carte Liberté s'adresse à tous les voyageurs fréquents",
    oldPrice: (
      <span className="text-lg text-black">
        19,95 €{" "}
        <span className="line-through text-xs text-[#5E6878]"> 399 €</span>
      </span>
    ),
    newPrice: "19,95 €",
    planValidity: "Annuel",
  },
];

const mobileImgs = [
  "/pass_rail.jpg",
  "/avantage_jeune.jpg",
  "/avantage_adulte.jpg",
  "/avantage_senior.jpg",
  "/carte_liberte.png",
];

const desktopImgs = [
  "/pass_rail.jpg",
  "/avantage_jeune.jpg",
  "/avantage_adulte.jpg",
  "/avantage_senior.jpg",
  "/carte_liberte.png",
];

export const mobileOffers = baseOffers.map((offer, index) => ({
  ...offer,
  img: mobileImgs[index],
}));

export const desktopOffers = baseOffers.map((offer, index) => ({
  ...offer,
  img: desktopImgs[index],
}));
